import React from 'react'
import T from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { withTheme } from '@material-ui/core'
import { SEOHeaders } from 'gatsby-components'

function SEO({ theme, ...props }) {
  // Gatsby's static queries must be a static string in a file in the gatsby app
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  return (
    <SEOHeaders
      site={site}
      bodyAttributes={{
        dir: theme.direction,
      }}
      {...props}
    />
  )
}

SEO.propTypes = {
  description: T.string,
  lang: T.string,
  meta: T.array,
  keywords: T.arrayOf(T.string),
  title: T.string.isRequired,
  theme: T.object.isRequired,
}

export default withTheme(SEO)
