import React from 'react'
import T from 'prop-types'
import {
  withStyles,
  Grid,
  Typography,
  Button,
  TextField,
  Hidden,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PaddedContainer from '../PaddedContainer'

function NewsletterSignup({ classes, hidden = false }) {
  const { t } = useTranslation()
  return hidden ? null : (
    <Grid item xs={12} sm={4}>
      <Typography variant="h4" gutterBottom>
        {t('subscribe to our newsletter')}
      </Typography>
      <Typography gutterBottom variant="body2">
        {t(
          'The latest EFQM news, articles, and resources, sent straight to your inbox every month.'
        )}
      </Typography>
      <div className={classes.horizontalContainer}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder={t('Your email address')}
        />
        <Button color="secondary" variant="contained">
          {t('subscribe')}
        </Button>
      </div>
    </Grid>
  )
}

NewsletterSignup.propTypes = {
  classes: T.object,
  hidden: T.bool,
}

function Footer({ classes, theme, content, Img, baseName, shopLink }) {
  const {
    site: {
      siteMetadata: { author, social, version },
    },
    socialIcons,
    footerLogo: {
      childImageSharp: { fixed: footerLogoFixed },
    },
    largeLogo: {
      childImageSharp: { fixed: logoFixed },
    },
  } = content
  const { t } = useTranslation()
  return (
    <div className={classes.root}>
      <PaddedContainer>
        <Grid container spacing={3} justify="space-between">
          <Grid item xs={12} sm={6}>
            <Typography variant="h4" gutterBottom>
              {t('Get in touch')}
            </Typography>
            <Typography component="div" variant="body2">
              <div>Avenue des Olympiades 2</div>
              <div>5th Floor</div>
              <div>B - 1140 Brussels, Belgium</div>
              <div>Tel: +322 755 3511</div>
              <div>
                Email:{' '}
                <a
                  href="mailto:info@efqm.org"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'inherit' }}
                >
                  info@efqm.org
                </a>
              </div>
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Grid container justify="flex-end">
              <Grid item sm={4} className={classes.licenseText}>
                <Typography variant="h4">
                  {`How can you have access to the EFQM ${baseName}`}
                </Typography>
                <Typography variant="body2">
                  {`You can buy your licence to the ${baseName} on`}
                </Typography>
                <Button
                  className={classes.shopButton}
                  href={shopLink}
                  color="secondary"
                  variant="contained"
                  fullWidth
                >
                  {t('EFQM Shop')}
                </Button>
              </Grid>
              <Grid item>
                <a href={shopLink}>
                  <Img fixed={footerLogoFixed} />
                </a>
              </Grid>
            </Grid>
          </Grid>
          <NewsletterSignup classes={classes} hidden />
          <Grid
            container
            spacing={3}
            justify="space-between"
            alignItems="flex-end"
          >
            <Grid item sm={6}>
              <Hidden xsDown implementation="css">
                <Grid container spacing={1} className={classes.terms}>
                  <Grid item>
                    <Typography display="inline" variant="body2">
                      © EFQM
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography display="inline" variant="body2">
                      {t('Terms of Use')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography display="inline" variant="body2">
                      {t('Privacy Statement')}
                    </Typography>
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
            <Grid item sm={6}>
              <div className={classes.socialAndLogo}>
                {social.map(([name, url]) => {
                  const icon = socialIcons.edges.find(item => {
                    const { node } = item
                    return (
                      node.childImageSharp &&
                      node.name.toLowerCase() === name.toLowerCase()
                    )
                  })
                  return (
                    icon && (
                      <a key={name} href={url} title={`${author} on ${name}`}>
                        <Img fixed={icon.node.childImageSharp.fixed} />
                      </a>
                    )
                  )
                })}
                <Hidden xsDown implementation="css">
                  <Img fixed={logoFixed} />
                </Hidden>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Hidden smUp implementation="css">
          <Img fixed={logoFixed} />
          <br />
          <br />
        </Hidden>
        <Hidden smUp implementation="css">
          <Grid container spacing={3} justify={'flex-start'}>
            <Grid item xs={12}>
              <Typography display="inline" variant="body2">
                © EFQM
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography display="inline" variant="body2">
                {t('Terms of Use')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography display="inline" variant="body2">
                {t('Privacy Statement')}
              </Typography>
            </Grid>
          </Grid>
        </Hidden>
      </PaddedContainer>
    </div>
  )
}

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.light,
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(3),
  },
  horizontalContainer: {
    display: 'flex',
    margin: theme.spacing(2, 0),
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    alignItems: 'flex-end',
  },
  terms: {
    margin: theme.spacing(0, 0, 2),
  },
  socialAndLogo: {
    alignItems: 'flex-end',
    display: 'flex',
    margin: theme.spacing(0, 0, 2),
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      marginTop: theme.spacing(2),
    },
  },
  licenseText: {
    '& > *': {
      margin: theme.spacing(0.75, 0.5, 1),
    },
  },
  shopButton: {
    width: '60%',
    minWidth: '120px',
  },
})

Footer.propTypes = {
  classes: T.object,
  theme: T.object,
  content: T.object.isRequired,
  Img: T.elementType,
  baseName: T.string.isRequired,
  shopLink: T.string.isRequired,
}

export default withStyles(styles, { withTheme: true })(Footer)
