import React, { useContext, useEffect, useState } from 'react'
import { ClientContext } from 'graphql-hooks'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import './src/styles/global.css'

import {
  AuthWrapper,
  AuthContext,
  RootWrapper,
  Layout,
  ThemeWrapper,
  makeGraphQLClient,
  initGraphQLClient,
  theme,
  useUserOrgs,
  useUserCertificates,
  AppFooter,
} from 'gatsby-components'
import MainToolbar from './src/components/MainToolbar'
import awsConfig from './utils/aws-exports'
//import * as i18n from './utils/i18n'

const muiTheme = createMuiTheme(theme.muiTheme)

const client = makeGraphQLClient(process.env.GATSBY_GRAPHQL_API)

export async function onClientEntry() {
  /*
  const i18next = await i18n.init()
  */
}

const AuthInitializationWrapper = ({ element }) => {
  return (
    <RootWrapper
      client={client}
      ClientContext={ClientContext}
      muiTheme={muiTheme}
      ThemeProvider={ThemeProvider}
      ThemeWrapper={ThemeWrapper}
      CssBaseline={CssBaseline}
    >
      <AuthWrapper>{element}</AuthWrapper>
    </RootWrapper>
  )
}
export const wrapRootElement = ({ element }) => {
  return <AuthInitializationWrapper element={element} />
}

const PageWrapper = ({ darkToolbar, location, children }) => {
  const { setIsAuthInitialized } = useContext(AuthContext)
  const [graphQLIsInitialized, setGraphQLIsInitialized] = useState(false)

  useUserOrgs()
  useUserCertificates()

  useEffect(() => {
    const init = async () => {
      setGraphQLIsInitialized(false)

      try {
        await initGraphQLClient(client, awsConfig)
      } catch {
        // error
      }

      setIsAuthInitialized(true)
      setGraphQLIsInitialized(true)
    }
    init()
  }, [location, setIsAuthInitialized])

  return (
    <Layout
      darkToolbar={darkToolbar}
      AppFooter={AppFooter}
      MainToolbar={MainToolbar}
      isInitialLoad={!graphQLIsInitialized}
    >
      {graphQLIsInitialized ? children : null}
    </Layout>
  )
}

export const wrapPageElement = ({ element, props }) => (
  <PageWrapper
    darkToolbar={props.location.pathname === '/'}
    location={props.location.pathname}
  >
    {element}
  </PageWrapper>
)
