const getContextDataForChartFunc = (key, data) => {
  const scores = data.scores || {}

  return {
    name: data.title,
    key,
    questionnaire_scores: Object.keys(scores).map(k => ({
      pillar_key: k,
      criterion_key: k,
      part_number: 1,
      scoring_values: { [k]: scores[k] },
    })),
  }
}

export { getContextDataForChartFunc }
