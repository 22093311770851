import { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { AuthContext } from 'gatsby-components'

export default function useUserOrgs() {
  const { setUserOrgs } = useContext(AuthContext)

  const orgs = useStaticQuery(
    graphql`
      query {
        raw_salmon {
          organization {
            id
            type
            name
          }
        }
      }
    `
  )

  setUserOrgs(orgs)
}
