import React from 'react'
import T from 'prop-types'

import AllUsers from './AllUsers.js'
import { getUsersInOrganization } from '../../../queries'

export default function OrganizationUsers({ orgIdString }) {
  return (
    <AllUsers
      query={getUsersInOrganization}
      variables={{ orgId: Number.parseInt(orgIdString) }}
    />
  )
}

OrganizationUsers.propTypes = {
  orgIdString: T.string,
}
