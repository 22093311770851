import { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { AuthContext } from 'gatsby-components'

export default function useUserCertificates() {
  const { setUserCertificates } = useContext(AuthContext)

  const certificates = useStaticQuery(
    graphql`
      query {
        raw_salmon {
          certificate {
            id
            key
            name
          }
        }
      }
    `
  )

  setUserCertificates(certificates)
}
