import React from 'react'
import T from 'prop-types'
import moment from 'moment'
import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import RoomIcon from '@material-ui/icons/Room'

import { flexWithGapColumn, flexWithGapRow } from 'gatsby-components'
import { navigate } from '@reach/router'

const useEventItemStyles = makeStyles(theme => ({
  dateAndTime: {
    ...theme.typography.h4,
    color: theme.palette.secondary.main,
  },
  location: {
    display: 'flex',
    alignItems: 'center',
  },
  locationIcon: {
    color: theme.palette.tertiary.main,
    marginRight: '0.1em',
    width: '0.7em',
  },
  locationText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  text: {
    ...flexWithGapColumn(theme)(0.5),
    flexBasis: '70%',
    minWidth: 0,
  },
  wrapper: {
    ...flexWithGapRow(theme)(2),
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '& a': {
      color: theme.palette.primary.dark,
      fontWeight: 700,
    },
  },
}))

const EventItem = ({ component = 'li', event }) => {
  const {
    dateAndTime,
    location: locationStyles,
    locationIcon,
    locationText,
    name: nameStyles,
    text,
    wrapper,
  } = useEventItemStyles()
  const { name, startTime, endTime, location, bookingLink, detailsLink } = event

  // Note that this can be got from a translation file
  const dateFormat = 'MMMM D - kk:mm'

  return (
    <Box className={wrapper} component={component} data-testid="event-item">
      <Box className={text}>
        <Typography className={nameStyles} component="h3">
          <a href={detailsLink} data-testid="event-name">
            {name}
          </a>
        </Typography>
        <Typography className={dateAndTime}>
          {moment(startTime).format(dateFormat)}&nbsp;-&nbsp;
          {moment(endTime).format(dateFormat)}
        </Typography>
        {typeof location === 'object' ? (
          <a className={locationStyles} href={location.link}>
            <RoomIcon className={locationIcon} />
            <Typography className={locationText}>{location.text}</Typography>
          </a>
        ) : (
          <div className={locationStyles}>
            <RoomIcon className={locationIcon} />
            <Typography className={locationText}>{location}</Typography>
          </div>
        )}
      </Box>
      <Button
        color="primary"
        variant="outlined"
        data-testid="button"
        onClick={() => {
          navigate(bookingLink)
        }}
      >
        Book
      </Button>
    </Box>
  )
}

EventItem.propTypes = {
  component: T.string,
  event: T.object.isRequired,
}

export default EventItem
