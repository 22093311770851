import React from 'react'
import T from 'prop-types'
import { Box, makeStyles, Typography, Grid } from '@material-ui/core'

import { flexWithGapColumn } from 'gatsby-components'

import bmwBase64 from 'gatsby-components/src/assets/partner-logos/bmw-base-64'
import boschBase64 from 'gatsby-components/src/assets/partner-logos/bosch-base-64'
import infineonBase64 from 'gatsby-components/src/assets/partner-logos/infineon-base-64'
import siemensBase64 from 'gatsby-components/src/assets/partner-logos/siemens-base-64'

const useStyles = makeStyles(theme => ({
  logo: { display: 'flex', justifyContent: 'center' },
  title: {
    ...theme.typography.h3,
    color: theme.palette.secondary.main,
  },
  wrapper: {
    ...flexWithGapColumn(theme)(4),
    alignItems: 'center',
  },
}))

const logos = [
  [boschBase64, 'Bosch'],
  [infineonBase64, 'Infineon'],
  [bmwBase64, 'BMW'],
  [siemensBase64, 'Siemens'],
]

const GainKnowledgeLinks = ({ logoGridItemProps = {}, hideTitle }) => {
  const classes = useStyles()

  return (
    <Box
      className={classes.wrapper}
      component="section"
      data-test-id="gain-knowledge-links"
    >
      {!hideTitle && (
        <Typography className={classes.title} component="h2">
          Gain knowledge from these leading companies
        </Typography>
      )}
      <Grid
        container
        spacing={4}
        direction="row"
        alignItems="center"
        justify="center"
      >
        {logos.map(logo => (
          <Grid
            item
            className={classes.logo}
            key={logo[1]}
            md={3}
            sm={6}
            xs={12}
            {...logoGridItemProps}
          >
            <img src={logo[0]} alt={logo[1]} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

GainKnowledgeLinks.propTypes = {
  logoGridItemProps: T.object,
  hideTitle: T.bool,
}

export default GainKnowledgeLinks
