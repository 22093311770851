/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react'
import T from 'prop-types'
import { withStyles, Typography } from '@material-ui/core'

import ChartTicks from '../ChartTicks'
import StyledSlider from './StyledSlider'
import Thumb from './Thumb'

function ScoringSlider({
  color,
  classes,
  label,
  value: initValue,
  tickCount,
  min,
  max,
  onChange,
  useSliderInternalState,
  ...props
}) {
  const [sliderInternalStateValue, setSliderInternalStateVal] = useState(0)

  useEffect(() => {
    setSliderInternalStateVal(initValue)
  }, [])

  const changeHandler = (e, value) => {
    if (useSliderInternalState) setSliderInternalStateVal(value)
    onChange(e, value)
  }

  const value = useSliderInternalState ? sliderInternalStateValue : initValue

  return (
    <div className={classes.container}>
      <Typography
        id="label"
        className={classes.label}
        variant="h4"
        gutterBottom
      >
        {label}
      </Typography>
      <StyledSlider
        color={color}
        className={classes.slider}
        aria-labelledby="label"
        value={value}
        ThumbComponent={thumbIconClass => props => (
          <Thumb value={value} thumbIconClass={thumbIconClass} {...props} />
        )}
        min={min}
        max={max}
        onChange={changeHandler}
        {...props}
      />
      <ChartTicks variant="below" tickCount={tickCount} min={min} max={max} />
    </div>
  )
}

ScoringSlider.propTypes = {
  classes: T.object.isRequired,
  label: T.string,
  useSliderInternalState: T.bool,
  ...StyledSlider.propTypes,
}

const styles = theme => ({
  container: {
    position: 'relative',
    marginRight: theme.spacing(4),
  },
  label: {
    color: theme.palette.primary.dark,
    position: 'absolute',
    left: 0,
    top: -35,
    width: '85%',
    marginTop: theme.spacing(2),
  },
  slider: {
    position: 'absolute',
    top: 10,
    left: 0,
  },
})

export default withStyles(styles)(ScoringSlider)
