import React from 'react'
import T from 'prop-types'
import get from 'lodash/get'
import classnames from 'classnames'
import { makeStyles, Typography } from '@material-ui/core'

const useListTitleStyles = makeStyles(theme => ({
  title: ({ paletteColor }) => ({
    borderTopWidth: '8px',
    borderTopColor: get(theme.palette, paletteColor),
    borderTopStyle: 'solid',
    paddingTop: theme.spacing(0.5),
  }),
}))

const ListTitle = ({
  paletteColor = ['primary', 'light'],
  title = '',
  className,
}) => {
  const { title: titleStyle } = useListTitleStyles({ paletteColor })
  return (
    <Typography
      variant="h3"
      className={classnames(titleStyle, className)}
      data-testid="list-title"
    >
      {title}
    </Typography>
  )
}

ListTitle.propTypes = {
  paletteColor: T.array,
  title: T.string,
  className: T.string,
}

export default ListTitle
