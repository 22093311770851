/* eslint-disable react/prop-types */
// prop-types were missing prior to trasfer to components. todo
import React, { useEffect, useState } from 'react'
import T from 'prop-types'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Tooltip,
  TableSortLabel,
  Typography,
  Paper,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

function QueryTableStatic({
  headers,
  pageSizes = [10, 20, 50],
  startingPageSize,
  renderTableBody,
  orderBy: orderByProp = { id: 'asc' },
  data,
  searchText,
  className,
}) {
  const { t } = useTranslation()
  const [offset, setOffset] = useState(0)
  const [pageSize, setPageSize] = useState(startingPageSize || pageSizes[0])
  const [orderBy, setOrderBy] = useState(orderByProp)
  const [total, setTotal] = useState(data.length)
  const [filteredData, setFilteredData] = useState([])
  const [displayData, setDisplayData] = useState(
    data.slice(offset, pageSize + offset)
  )

  const regex = new RegExp(searchText, 'i')

  useEffect(() => {
    if (data && searchText) {
      const filteredData = data.filter(obj =>
        Object.values(obj).some(str => str && str.match(regex))
      )

      setFilteredData(filteredData)
      setDisplayData(filteredData.slice(offset, pageSize + offset))
      setTotal(filteredData.length)
    }
  }, [data, searchText])

  headers = headers.map(x => ({ ...x, sortable: false }))

  if (!headers.length)
    return (
      <Typography>
        {t('queryTable.noTableHeaders', 'No table headers to show')}
      </Typography>
    )

  if (!displayData)
    return <Typography>{t('common.loading', 'Loading...')}</Typography>

  const onChangePage = (event, page) => {
    const fullData = searchText ? filteredData : data

    setDisplayData(fullData.slice(page * pageSize, pageSize + page * pageSize))
    setOffset(page * pageSize)
  }

  return (
    <Paper className={className}>
      {displayData && (
        <Table>
          <TableHead>
            <TableRow>
              {headers.map(({ id, sortable, cellProps, label }) => {
                if (!sortable) {
                  return (
                    <TableCell key={id} {...cellProps}>
                      {label}
                    </TableCell>
                  )
                }
                const columnIsOrdered = orderBy.hasOwnProperty(id)
                return (
                  <TableCell
                    key={id}
                    sortDirection={columnIsOrdered ? orderBy[id] : false}
                    {...cellProps}
                  >
                    <Tooltip title={t('common.sort', 'Sort')} enterDelay={300}>
                      <TableSortLabel
                        active={columnIsOrdered}
                        direction={orderBy[id]}
                        onClick={() =>
                          setOrderBy({
                            [id]: orderBy[id] === 'asc' ? 'desc' : 'asc',
                          })
                        }
                      >
                        {label}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>{renderTableBody({ data: displayData })}</TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={pageSizes}
                colSpan={headers.length}
                count={total}
                rowsPerPage={pageSize}
                page={Math.floor(offset / pageSize)}
                onChangePage={onChangePage}
                onChangeRowsPerPage={event => setPageSize(event.target.value)}
              />
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </Paper>
  )
}

QueryTableStatic.propTypes = {
  headers: T.arrayOf(
    T.shape({
      id: T.string.isRequired,
      label: T.string.isRequired,
      sortable: T.bool,
      cellProps: T.object,
    })
  ).isRequired,
  pageSizes: T.arrayOf(T.number),
  startingPageSize: T.number,
  renderTableBody: T.func.isRequired,
  data: T.array,
}

export default QueryTableStatic
