import React from 'react'
import T from 'prop-types'
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Tooltip,
  TableSortLabel,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export default function AdminTable({
  children,
  data,
  headers,
  offset,
  pageSize,
  orderBy,
  setPageSize,
  setOrderBy,
  setOffset,
  pageSizes,
  hidePaginationFooter,
}) {
  const { t } = useTranslation()
  const toggleOrder = order => (order === 'asc' ? 'desc' : 'asc')

  const handleChangePage = (event, page) => setOffset(page * pageSize)

  const handleChangePageSize = event => setPageSize(event.target.value)

  const handleChangeOrderBy = property =>
    setOrderBy({ [property]: toggleOrder(orderBy[property]) })

  if (!headers.length) return 'No table headers to show'
  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map(header => {
              if (!header.sortable) {
                return (
                  <TableCell key={header.id} {...header.cellProps}>
                    {t(header.label)}
                  </TableCell>
                )
              }
              const columnIsOrdered = orderBy.hasOwnProperty(header.id)
              return (
                <TableCell
                  key={header.id}
                  sortDirection={columnIsOrdered ? orderBy[header.id] : false}
                  {...header.cellProps}
                >
                  <Tooltip title="Sort" enterDelay={300}>
                    <TableSortLabel
                      active={columnIsOrdered}
                      direction={orderBy[header.id]}
                      onClick={e => handleChangeOrderBy(header.id)}
                    >
                      {t(header.label)}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
        {!hidePaginationFooter && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={pageSizes}
                colSpan={headers.reduce(
                  (a, header) =>
                    a +
                    (header?.cellProps?.colSpan
                      ? header?.cellProps?.colSpan
                      : 1),
                  0
                )}
                count={data.field_aggregate.aggregate.count}
                rowsPerPage={pageSize}
                page={Math.floor(offset / pageSize)}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangePageSize}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </Paper>
  )
}

AdminTable.defaultProps = {
  orderBy: { id: 'asc' },
}

AdminTable.propTypes = {
  children: T.node,
  data: T.object.isRequired,
  headers: T.arrayOf(
    T.shape({
      id: T.string.isRequired,
      label: T.string.isRequired,
      sortable: T.bool,
      cellProps: T.object,
    })
  ).isRequired,
  pageTitle: T.node,
  offset: T.number.isRequired,
  pageSize: T.number.isRequired,
  orderBy: T.object.isRequired,
  setPageSize: T.func.isRequired,
  setOrderBy: T.func.isRequired,
  setOffset: T.func.isRequired,
  pageSizes: T.arrayOf(T.number).isRequired,
  hidePaginationFooter: T.bool,
}
