import React, { useState } from 'react'
import T from 'prop-types'
import {
  withStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

function ConfirmDialog({
  classes,
  children,
  onConfirm,
  onOpen = () => {},
  onCancel = () => {},
  title,
  type,
  okayLabel,
  cancelLabel,
  text,
  inputElement,
  waitForAsync,
  disabled,
  submitDisabled,
  className,
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  function handleOpen() {
    if (!disabled) {
      onOpen()
      setIsOpen(true)
    }
  }
  function handleClose() {
    onCancel()
    setIsOpen(false)
  }
  async function handleConfirm() {
    if (waitForAsync) {
      setLoading(true)
      try {
        await onConfirm()
      } catch {
        setLoading(false)
        return
      }
      setIsOpen(false)
      setLoading(false)
    } else {
      onConfirm()
      setIsOpen(false)
    }
  }

  return (
    <>
      <Dialog
        className={className}
        open={isOpen && !disabled}
        onBackdropClick={handleClose}
      >
        <DialogTitle disableTypography>
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>
        </DialogTitle>
        {text && <DialogContent className={classes.text}>{text}</DialogContent>}
        {inputElement}
        <DialogActions className={classes.actions}>
          <Button color="secondary" variant="outlined" onClick={handleClose}>
            {t(cancelLabel)}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleConfirm}
            type={type} // For example, type="submit" on form submit actions
            disabled={submitDisabled}
            autoFocus
          >
            {!loading ? (
              t(okayLabel)
            ) : (
              <CircularProgress size={14} thickness={5} color="inherit" />
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <span onClick={handleOpen}>{children}</span>
    </>
  )
}

const styles = theme => ({
  title: {
    color: theme.palette.primary.dark,
  },
  text: theme.typography.body2,
  actions: {
    padding: theme.spacing(2),
  },
})

ConfirmDialog.defaultProps = {
  okayLabel: 'Okay',
  cancelLabel: 'Cancel',
  disabled: false,
}

ConfirmDialog.propTypes = {
  classes: T.object.isRequired,
  children: T.node.isRequired,
  onConfirm: T.func.isRequired,
  onOpen: T.func,
  onCancel: T.func,
  title: T.string.isRequired,
  okayLabel: T.string,
  cancelLabel: T.string,
  text: T.node,
  type: T.string,
  inputElement: T.elementType,
  waitForAsync: T.bool,
  disabled: T.bool,
  submitDisabled: T.bool,
  className: T.string,
}

export default withStyles(styles)(ConfirmDialog)
