import React, { useContext, useEffect, useState } from 'react'
import T from 'prop-types'
import { useTranslation } from 'react-i18next'
import { navigate } from 'gatsby'
import {
  withStyles,
  Chip,
  Grid,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core'
import {
  Assignment as AssignmentIcon,
  Face as FaceIcon,
  KeyboardArrowRight as ArrowIcon,
} from '@material-ui/icons'
import classnames from 'classnames'

import { formatLocalizedDate } from 'gatsby-components/src/utils/date'
import { getAdminActionItems } from '../../../queries'
import useAdminTable from '../../hooks/useAdminTable'
import { AuthContext } from '../authorization/AuthWrapper'

function ActionTable({ whereClause, pageSizes, classes }) {
  const { t, i18n } = useTranslation()
  const lang = i18n.language || 'en'

  const { getUserTokenData, hasPermissions } = useContext(AuthContext)
  const userTokenData = getUserTokenData()

  const headers = [
    { id: 'date', label: t('Action date') },
    {
      id: 'action',
      label: t('Action required'),
      cellProps: {
        colSpan: 2,
      },
    },
    { id: 'type', label: t('Type') },
    {
      id: 'priority',
      label: t('Priority'),
    },
    {
      id: 'arrow',
      label: '',
    },
  ]

  const actionTypeIcons = key => {
    switch (key) {
      case 'user':
        return FaceIcon
      default:
        return AssignmentIcon
    }
  }

  const actionRequiredText = key => {
    switch (key) {
      case 'user':
        return t('Assign this new user to a group')
      default:
        return t('Assessment submitted - Needs Assessor assigned')
    }
  }

  const onRowClick = (key, id) => () => {
    switch (key) {
      case 'user':
        return navigate('/admin/users/pending')
      default:
        return navigate(`/assessment/${key}/#${id}`)
    }
  }

  const actionTypeLabel = key => {
    switch (key) {
      case 'user':
        return t('User')
      default:
        return t('Assessment')
    }
  }

  const { table } = useAdminTable({
    query: getAdminActionItems,
    headers,
    options: { pageSizes },
    variables: {
      isInternal: !userTokenData.isPlatformAdmin,
    },
    renderTableBody: data =>
      data.actions.map(({ id, key, priority, created_at: createdAt }) => {
        const TypeIcon = actionTypeIcons(key)

        return (
          <TableRow key={id} size="small" hover onClick={onRowClick(key, id)}>
            <TableCell>
              <Typography variant="body2">
                {formatLocalizedDate(createdAt, lang === 'en' ? 'en-GB' : lang)}
              </Typography>
            </TableCell>
            <TableCell colSpan={2}>
              <Typography variant="body2">{actionRequiredText(key)}</Typography>
            </TableCell>
            <TableCell>
              <Grid container alignItems="flex-end">
                <Grid item className={classes.typeIconGrid}>
                  <TypeIcon />
                </Grid>
                <Grid item>
                  <Chip
                    label={actionTypeLabel(key)}
                    className={classnames(classes.chips, classes.typeChip)}
                  />
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Chip
                label={t(`priority.${priority}`)}
                className={classnames(
                  classes.chips,
                  classes.priorityChip,
                  priority
                )}
              />
            </TableCell>
            <TableCell>
              <ArrowIcon />
            </TableCell>
          </TableRow>
        )
      }),
  })

  return table
}
ActionTable.propTypes = {
  whereClause: T.object.isRequired,
  classes: T.object.isRequired,
}

const styles = theme => ({
  typeIconGrid: {
    height: '24px',
    width: '24px',
    marginRight: theme.spacing(1),
  },
  chips: {
    height: '20px',
    textTransform: 'capitalize',
  },
  typeChip: {
    backgroundColor: '#e4f5f4',
  },
  priorityChip: {
    '&.medium': {
      backgroundColor: 'rgba(80, 184, 242, 0.1)',
    },
    '&.high': {
      backgroundColor: 'rgba(80, 184, 242, 0.42)',
    },
    '&.urgent': {
      backgroundColor: 'rgba(243, 68, 68, 0.42)',
    },
  },
})

export default withStyles(styles)(ActionTable)
