/* eslint-disable react/prop-types */
import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { Paper, Typography, Grid, withStyles } from '@material-ui/core'
import Img from 'gatsby-image'

const QuoteCarousel = ({ quotes, classes, namespace }) => {
  const { t } = useTranslation()
  return (
    <Paper className={classes.root}>
      <Carousel autoPlay timer={500} animation="fade" indicators timeout={500}>
        {quotes.map(({ quote, by, image }, i) => (
          <Paper elevation={0} key={`quote_${i}`}>
            <Grid
              container
              direction="column"
              justify="space-between"
              alignItems="center"
              className={classes.quoteGridContainer}
            >
              <Grid item container>
                <Grid item xs={1}>
                  <Typography variant="body1" className={classes.quotationMark}>
                    {'“'}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body1" className={classes.quoteText}>
                    {t(`findOutMore.header.quotes.${i}.quote`, {
                      defaultValue: quote,
                      ns: namespace,
                      keySeparator: '.',
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    variant="body1"
                    className={classnames(
                      classes.quotationMark,
                      classes.bottomQuotationMark
                    )}
                    style={{ top: 10 * Math.round(quote.length / 40) }}
                  >
                    {'”'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                item
                direction="column"
                justify="center"
                alignItems="center"
              >
                {image && (
                  <Grid item>
                    <Img
                      className={classes.quoteImage}
                      fixed={image.childImageSharp.fixed}
                    />
                  </Grid>
                )}
                <Grid item>
                  <Typography variant="h3" className={classes.quoteName}>
                    {by}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </Carousel>
    </Paper>
  )
}

const styles = theme => ({
  root: {
    width: '544px',
    height: '292px',
    borderRadius: '10px',
    '& > *': {
      height: '100%',
    },
  },
  quoteGridContainer: { height: '262px' },
  quotationMark: {
    fontSize: 82,
    fontStyle: 'italic',
    color: '#a6b8ca',
    fontWeight: 'bold',
    opacity: 0.54,
    marginLeft: theme.spacing(-1),
  },
  bottomQuotationMark: {
    position: 'relative',
  },
  quoteText: {
    fontSize: 21,
    fontStyle: 'italic',
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: 1.14,
    marginTop: theme.spacing(4),
  },
  quoteImage: {
    borderRadius: '50%',
  },
  quoteName: {
    textAlign: 'center',
    margin: theme.spacing(1, 4, 0, 4),
  },
})

export default withStyles(styles, { withTheme: true })(QuoteCarousel)
