/* eslint-disable react/prop-types */
// prop-types were missing prior to trasfer to components. todo
import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import { flexWithGapColumn, ListTitle, SmallPreview } from 'gatsby-components'

const useThemedListStyles = makeStyles(theme => ({
  listLayout: {
    ...flexWithGapColumn(theme)(4),
    padding: `${theme.spacing(2)}px 0 0 0`,
    '& li': {
      listStyle: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
    },
  },
}))

const ThemedList = ({ title = '', articles = [], hideEmpty }) => {
  const { listLayout } = useThemedListStyles()
  if (hideEmpty && !articles.length) return null
  return (
    <Box data-testid="themed-list">
      <ListTitle title={title} />
      <Box className={listLayout} component="ul">
        {articles.map(article => (
          <SmallPreview key={'article_' + article.id} article={article} />
        ))}
      </Box>
    </Box>
  )
}

ThemedList.propTypes = {
  title: PropTypes.string,
  articles: PropTypes.array,
  classes: PropTypes.object,
}

export default ThemedList
