import React from 'react'
import { withStyles, Typography } from '@material-ui/core'
import classnames from 'classnames'
import T from 'prop-types'

function SectionTitle({ classes, barColor, className, ...props }) {
  return (
    <div>
      <div className={classes.bar} style={{ backgroundColor: barColor }} />
      <Typography
        variant="h3"
        className={classnames([classes.title, className])}
        {...props}
      />
    </div>
  )
}

SectionTitle.propTypes = {
  ...Typography.propTypes,
  barColor: T.string.isRequired,
}

const styles = theme => ({
  bar: {
    height: theme.spacing(1),
    width: '100%',
    marginBottom: theme.spacing(0.5),
    '-webkit-print-color-adjust': 'exact',
  },
  title: {
    '& > a': {
      color: 'inherit',
    },
  },
})

export default withStyles(styles)(SectionTitle)
