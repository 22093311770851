module.exports = {
  assetsPath: 'assets',
  assessmentsPath: 'assessments',
  knowledgeTypesPath: 'knowledgeTypes',
  heroImageNameAB: 'bannerHeroAB',
  heroImageNameABSimple: 'bannerHeroABSimple',
  heroImageNameKB: 'bannerHeroKB',
  modelImageAB: 'modelImageAB',
  translations: {
    en: {
      abb: 'en',
      name: 'English',
      flag: '🇬🇧',
    },
    ar: {
      abb: 'ar',
      name: 'العربية',
      flag: '🇦🇪',
    },
    'cs-CZ': {
      abb: 'cs-CZ',
      name: 'Čeština',
      flag: '🇨🇿',
    },
    zh: {
      abb: 'zh',
      name: 'Chinese (Simplified)',
      flag: '🇨🇳',
    },
    'da-DK': {
      abb: 'da-DK',
      name: 'Dansk',
      flag: '🇩🇰',
    },
    de: {
      abb: 'de',
      name: 'Deutsch',
      flag: '🇩🇪',
    },
    es: {
      abb: 'es',
      name: 'Español',
      flag: '🇪🇸',
    },
    fr: {
      abb: 'fr',
      name: 'Français',
      flag: '🇫🇷',
    },
    'it-IT': {
      abb: 'it-IT',
      name: 'Italiano',
      flag: '🇮🇹',
    },
    'sl-SI': {
      abb: 'sl-SI',
      name: 'Slovenščina',
      flag: '🇸🇮',
    },
    'tr-TR': {
      abb: 'tr-TR',
      name: 'Turkish',
      flag: '🇹🇷',
    },
  },
  hbTranslations: {
    en: {
      abb: 'en',
      countryCode: 'GB',
      name: 'English',
      flag: '🇬🇧',
    },
    ar: {
      abb: 'ar',
      countryCode: 'AE',
      name: 'العربية',
      flag: '🇦🇪',
    },
    de: {
      abb: 'de',
      countryCode: 'DE',
      name: 'Deutsch',
      flag: '🇩🇪',
    },
    es: {
      abb: 'es',
      countryCode: 'ES',
      name: 'Español',
      flag: '🇪🇸',
    },
    fr: {
      abb: 'fr',
      countryCode: 'FR',
      name: 'Français',
      flag: '🇫🇷',
    },
  },
}
