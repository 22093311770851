import React, { useState, useContext } from 'react'
import { useQuery, useMutation } from 'graphql-hooks'
import classnames from 'classnames'
import T from 'prop-types'
import {
  withStyles,
  TableRow,
  TableCell,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import { HowToReg, ErrorOutline, Edit, DeleteForever } from '@material-ui/icons'
import * as Yup from 'yup'
import { ConfirmDialog } from 'gatsby-components'
import get from 'lodash/get'
import { CheckboxWithLabel } from 'formik-material-ui'
import { Field } from 'formik'

import UserRoleChip from '../StatusChip/UserRoleChip'
import useAdminTable from '../../hooks/useAdminTable'
import AdminModal from './AdminModal'
import UserSelectPicker from './UserSelectPicker'
import { AuthContext } from '../authorization/AuthWrapper'
// import { AdminDeleteUser } from 'aws-amplify-react'

import {
  getUsersAdminTable,
  getEdittableUserFields,
  getCertificatesForAdminUserTable,
  addUserGroupMutation,
  assignUserGroupMutation,
  addUserRoleMutation,
  assignUserRoleMutation,
  createUserCertificateMutation,
  deleteUserCertificateMutation,
  updateUserMutation,
  deleteUserMutation,
  deleteUserRoleMutation,
} from '../../../queries'

const styles = theme => ({
  middle: {
    verticalAlign: 'middle',
  },
  assigned: {
    color: theme.palette.secondary.main,
  },
  testThisClass: {
    marginTop: theme.spacing(1),
  },
})

const headers = [
  { id: 'id', label: 'ID', sortable: true },
  { id: 'name', label: 'Name' },
  { id: 'orgName', label: 'Org name' },
  { id: 'group', label: 'Group' },
  { id: 'role', label: 'Role' },
  {
    id: 'edit',
    label: 'Edit',
    cellProps: {
      align: 'center',
    },
  },
  { id: 'delete', label: 'Delete' },
]

function getRelationalName(user, relation, entity) {
  const entries = user[relation]
  return entries.length && entries[0][entity] ? entries[0][entity].name : null
}

function getStyledSignupAttr(user, key) {
  if (!user.signupRequest || !user.signupRequest.userAttributes[key])
    return (
      <Typography variant="body2" color="textSecondary">
        None
      </Typography>
    )

  return (
    <Typography variant="body2">
      {user.signupRequest.userAttributes[key]}
    </Typography>
  )
}

function unassignedTextAndIcon(classes) {
  return (
    <Typography variant="body2" noWrap color="textSecondary">
      <ErrorOutline
        className={classes.middle}
        fontSize="small"
        color="inherit"
      />
      <span>Unassigned</span>
    </Typography>
  )
}

function getGroupAndStatus(user, classes) {
  const groupName = getRelationalName(user, 'user_group2s', 'group')
  return (
    <Tooltip
      noWrap
      title={user.pending ? 'Pending User' : 'Assigned User'}
      placement="top"
    >
      {user.pending ? (
        unassignedTextAndIcon(classes)
      ) : (
        <Typography variant="body2" noWrap color="textPrimary">
          <HowToReg
            className={classnames(classes.middle, classes.assigned)}
            fontSize="small"
            color="inherit"
          />
          <span>{groupName}</span>
        </Typography>
      )}
    </Tooltip>
  )
}

function AllUsers({ classes, query, variables, whereClause }) {
  variables.whereClause = whereClause

  const { getUserTokenData } = useContext(AuthContext)
  const {
    groupId,
    role,
    orgId,
    userId,
    isPartner,
    isPlatform,
    isOrgAdmin,
  } = getUserTokenData()
  const canGiveCertificates = (isPartner || isPlatform) && isOrgAdmin

  const givenUserCanBeDeleted = givenUser => {
    const givenUserIsAdmin =
      get(givenUser, 'user_roles[0].role.name') === 'admin'
    const givenUserIsOrgAdmin =
      get(givenUser, 'user_roles[0].role.name') === 'org-admin'
    const givenUserIsGroupAdmin =
      get(givenUser, 'user_roles[0].role.name') === 'group-admin'
    const givenUserIsInSameGroup =
      get(givenUser, 'user_group2s[0].group.id') === Number(groupId)
    const givenUserIsInSameOrg =
      get(givenUser, 'organization.id') === Number(orgId)
    // TODO set group-admin delete
    switch (role) {
      case 'admin':
        return true
      case 'partner-admin':
        // can't delete admins who are within same user group
        return !givenUserIsAdmin || !givenUserIsInSameGroup
      case 'org-admin':
        // can't delete admins who are within same user Org
        return !(givenUserIsOrgAdmin && givenUserIsInSameOrg)
      case 'group-admin':
        // can't delete org admins or group admins who are within same user group
        return !(
          (givenUserIsGroupAdmin && givenUserIsInSameOrg) ||
          givenUserIsOrgAdmin
        )
      case 'company-admin':
        // can't delete admins
        return !givenUserIsAdmin
      default:
        return false
    }
  }

  const { table, selected, setSelected, refetch } = useAdminTable({
    query,
    headers,
    variables,
    renderTableBody: (data, { refetch: refetchUsers }) => {
      const doDeleteUser = async (id, cognitoId, pending) => {
        // eslint-disable-next-line no-unused-expressions
        pending
          ? await deleteUser({ variables: { userId: id } })
          : await modifyUser({
              variables: {
                id: id,
                input: {
                  email: null,
                  consent_contact: false,
                  consent_directory: false,
                  title: null,
                  avatar: null,
                  biography: null,
                  linkedin: null,
                  website: null,
                  twitter: null,
                  active: false,
                  organization_id: null,
                },
              },
            })
        // AdminDeleteUser({
        //   Username: cognitoId,
        //   UserPoolId: process.env.GATSBY_AWS_COGNITO_IDENTITY_POOL_ID,
        // }) // this function has been removed from react package, a Lambda needs to be set up and use the aws-sdk inorder to delete users as admin now, otherwise a user would need to personally delete their own account
        refetchUsers()
      }

      return data.user.map(user => (
        <TableRow key={user.id.toString()} data-testid="all-users" size="small">
          <TableCell>
            <Typography variant="body2">{user.id}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">{`${user?.first_name ||
              ''} ${user?.last_name || ''} `}</Typography>
          </TableCell>
          <TableCell>
            {get(user, 'organization.name', unassignedTextAndIcon(classes))}
          </TableCell>
          <TableCell>{getGroupAndStatus(user, classes)}</TableCell>
          <TableCell>
            <UserRoleChip
              status={
                getRelationalName(user, 'user_roles', 'role') || 'non-member'
              }
            />
          </TableCell>
          <TableCell align="center" padding="none">
            <IconButton onClick={() => setSelected(user)}>
              <Edit color="secondary" />
            </IconButton>
          </TableCell>
          <TableCell align="right" padding="none">
            {givenUserCanBeDeleted(user) && (
              <ConfirmDialog
                title={`Delete user “${user.email}”?`}
                text="This user will be permanently deleted. This cannot be undone."
                onConfirm={() =>
                  doDeleteUser(user.id, user.cognito_id, user.pending)
                }
                okayLabel="Delete"
              >
                <IconButton className={classes.actionButton}>
                  <DeleteForever />
                </IconButton>
              </ConfirmDialog>
            )}
          </TableCell>
        </TableRow>
      ))
    },
  })

  const { data: modalData } = useQuery(getEdittableUserFields)
  const { data: certificateData } = useQuery(getCertificatesForAdminUserTable)
  const [doAddUserGroup] = useMutation(addUserGroupMutation)
  const [doAssignUserGroup] = useMutation(assignUserGroupMutation)
  const [doAddUserRole] = useMutation(addUserRoleMutation)
  const [doAssignUserRole] = useMutation(assignUserRoleMutation)
  const [doDeleteUserRole] = useMutation(deleteUserRoleMutation)
  const [deleteUser] = useMutation(deleteUserMutation)
  const [modifyUser] = useMutation(updateUserMutation)
  const [createUserCertificate] = useMutation(createUserCertificateMutation)
  const [deleteUserCertificate] = useMutation(deleteUserCertificateMutation)

  const modalContents = [
    {
      label: 'Group',
      FieldComponent: UserSelectPicker,
      entityKey: 'group2',
      sortById: 'name',
    },
    {
      label: 'Role',
      FieldComponent: UserSelectPicker,
      entityKey: 'role',
      emptyLabel: 'non-member',
    },
    ...(canGiveCertificates
      ? [
          {
            FieldComponent: function CheckboxField(props) {
              return (
                <Field
                  component={CheckboxWithLabel}
                  name="assessor"
                  Label={{ label: 'Is an assessor' }}
                  // eslint-disable-next-line react/prop-types
                  checked={props.values.assessor}
                />
              )
            },
            entityKey: 'assessor',
          },
          {
            FieldComponent: function CheckboxField(props) {
              return (
                <Field
                  component={CheckboxWithLabel}
                  name="consultant"
                  Label={{ label: 'Is a consultant' }}
                  // eslint-disable-next-line react/prop-types
                  checked={props.values.consultant}
                />
              )
            },
            entityKey: 'consultant',
          },
          {
            FieldComponent: function CheckboxField(props) {
              return (
                <Field
                  component={CheckboxWithLabel}
                  name="certified-advisor"
                  Label={{ label: 'Is a certified advisor' }}
                  // eslint-disable-next-line react/prop-types
                  checked={props.values['certified-advisor']}
                />
              )
            },
            entityKey: 'certified-advisor',
          },
          {
            FieldComponent: function CheckboxField(props) {
              return (
                <Field
                  component={CheckboxWithLabel}
                  name="certified-trainer"
                  Label={{ label: 'Is a certified trainer' }}
                  // eslint-disable-next-line react/prop-types
                  checked={props.values['certified-trainer']}
                />
              )
            },
            entityKey: 'certified-trainer',
          },
        ]
      : []),
  ]

  if (modalData) {
    modalContents.forEach(({ entityKey, sortById }) => {
      if (sortById) {
        modalData[entityKey].sort(function(
          { [sortById]: a },
          { [sortById]: b }
        ) {
          if (typeof a === 'string') {
            a = a.toLowerCase()
            b = b.toLowerCase()
          }
          if (a < b) {
            return -1
          }
          if (a > b) {
            return 1
          }
          return 0
        })
      }
    })
  }

  const modalSchema = Yup.object().shape({
    userId: Yup.number().integer(),
    groupId: Yup.number().integer(),
    groupIsAssigned: Yup.boolean(),
    roleId: Yup.number().integer(),
    roleIsAssigned: Yup.boolean(),
    assessor: Yup.boolean(),
    consultant: Yup.boolean(),
    'certified-advisor': Yup.boolean(),
    'certified-trainer': Yup.boolean(),
  })

  const onModalSave = async (values, user) => {
    const initialValues = getModalInitialValues(user)
    // Do mutation queries in parallel
    const mutationPromises = []

    if (canGiveCertificates) {
      for (let certKey of [
        'assessor',
        'consultant',
        'certified-advisor',
        'certified-trainer',
      ]) {
        if (initialValues[certKey] == false && values[certKey]) {
          mutationPromises.push(
            createUserCertificate({
              variables: {
                certificateId: certificateData?.certificate.find(
                  ({ key }) => key === certKey
                )?.id,
                userId: values.userId,
                awardingUserId: userId,
                awardingUserOrgId: orgId,
              },
            })
          )
        }

        if (initialValues[certKey] && values[certKey] == false) {
          mutationPromises.push(
            deleteUserCertificate({
              variables: {
                uuid: user.user_certificates.find(
                  ({ certificate }) => certificate.key === certKey
                )?.uuid,
              },
            })
          )
        }
      }
    }

    if (values.group2Id) {
      const groupMutation = values.groupIsAssigned
        ? doAssignUserGroup
        : doAddUserGroup

      const orgId = get(
        modalData.group2.find(({ id }) => id === values.group2Id),
        'organization.id'
      )

      mutationPromises.push(
        groupMutation({
          variables: {
            userId: values.userId,
            groupId: values.group2Id,
            orgId,
          },
        })
      )
    }
    if (values.roleId) {
      const roleMutation = values.roleIsAssigned
        ? doAssignUserRole
        : doAddUserRole

      mutationPromises.push(
        roleMutation({
          variables: {
            userId: values.userId,
            roleId: values.roleId,
          },
        })
      )
    } else if (values.roleIsAssigned) {
      mutationPromises.push(
        doDeleteUserRole({
          variables: {
            userId: values.userId,
          },
        })
      )
    }
    await Promise.all(mutationPromises)
    setSelected(null)

    // Only refetch if something changed
    if (mutationPromises.length) {
      refetch()
    }
  }

  const getModalInitialValues = user => {
    const groupId = get(user, 'user_group2s.0.group.id')
    const roleId = get(user, 'user_roles.0.role.id')
    const certificates = get(user, 'user_certificates')

    return {
      userId: user ? user.id : null,
      group2Id: groupId || 0,
      groupIsAssigned: !!groupId,
      roleId: roleId || 0,
      roleIsAssigned: !!roleId,
      assessor:
        certificates.filter(({ certificate }) => certificate.key === 'assessor')
          .length > 0,
      consultant:
        certificates.filter(
          ({ certificate }) => certificate.key === 'consultant'
        ).length > 0,
      'certified-advisor':
        certificates.filter(
          ({ certificate }) => certificate.key === 'certified-advisor'
        ).length > 0,
      'certified-trainer':
        certificates.filter(
          ({ certificate }) => certificate.key === 'certified-trainer'
        ).length > 0,
    }
  }

  return (
    <>
      <AdminModal
        selected={selected}
        data={modalData}
        contents={modalContents}
        getTitleParts={user => ['Edit', user.email]}
        onSave={onModalSave}
        onClose={() => setSelected(null)}
        schema={modalSchema}
        getInitialValues={getModalInitialValues}
      />
      {table}
    </>
  )
}

AllUsers.defaultProps = {
  query: getUsersAdminTable,
  pageTitle: 'All Users',
  variables: {},
}

AllUsers.propTypes = {
  classes: T.object,
  query: T.string,
  pageTitle: T.node,
  variables: T.object,
  whereClause: T.object,
}

export default withStyles(styles)(AllUsers)
