import React from 'react'
import T from 'prop-types'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { Link } from 'gatsby'
import classnames from 'classnames'
import ArrowIcon from '@material-ui/icons/ArrowRightAlt'

import {
  ListItem,
  ListItemText,
  Chip,
  Typography,
  withStyles,
} from '@material-ui/core'

const LinkWithActiveStyling = ({
  children,
  activeClassName,
  childLinks,
  className,
  ...props
}) => (
  <Link
    getProps={({ isPartiallyCurrent, href }) => ({
      className: classnames(className, {
        [activeClassName]: isPartiallyCurrent || childLinks.includes(href),
      }),
    })}
    {...props}
  >
    {children}
  </Link>
)

LinkWithActiveStyling.propTypes = {
  children: T.node,
  activeClassName: T.string,
  childLinks: T.array,
  className: T.string,
}

function CustomListItem({
  to,
  name,
  key,
  Icon,
  chipValue,
  childLinks,
  classes,
}) {
  return (
    <ListItem
      button
      component={to ? LinkWithActiveStyling : undefined}
      to={to}
      key={key}
      childLinks={childLinks}
      className={classes.root}
      activeClassName={classes.activeLink}
    >
      {Icon ? (
        <Icon className={classes.icon} fontSize="small" />
      ) : window?.location.href.includes(to) ? (
        <ArrowIcon className={classes.icon} fontSize="small" />
      ) : (
        <div className={classes.iconPlaceholder} />
      )}
      <ListItemText
        primary={
          <Typography className={classes.text} variant="h3">
            {name}
          </Typography>
        }
        disableTypography
      />
      {chipValue !== undefined && (
        <Chip
          className={classes.chip}
          label={
            <Typography className={classes.chipLabel}>{chipValue}</Typography>
          }
        />
      )}
    </ListItem>
  )
}

const styles = theme => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    '& > .MuiSvgIcon-root': {
      color: theme.palette.primary.dark,
      margin: theme.spacing(0, 2, 0, 0),
    },
    '&:hover': {
      backgroundColor: fade(theme.palette.secondary.main, 0.11),
      '& > .MuiSvgIcon-root': {
        color: fade(theme.palette.secondary.main, 1),
      },
    },
  },
  iconPlaceholder: {
    height: '20px',
    width: '20px',
    margin: theme.spacing(0, 2, 0, 1),
  },
  activeLink: {
    backgroundColor: fade(theme.palette.secondary.main, 0.11),
    '& > .MuiSvgIcon-root': {
      color: `${fade(theme.palette.secondary.main, 1)} !important`,
    },
  },
  text: {
    color: theme.palette.primary.dark,
  },
  chip: {
    backgroundColor: '#e4f5f4',
    color: theme.palette.primary.dark,
    height: '20px',
  },
  chipLabel: {
    fontSize: '12px',
  },
})

CustomListItem.propTypes = {
  to: T.string,
  key: T.string,
  name: T.string.isRequired,
  Icon: T.object,
  chipValue: T.number,
  childLinks: T.array,
  classes: T.object,
}

CustomListItem.defaultProps = {
  to: '',
  childLinks: [],
}

export default withStyles(styles)(CustomListItem)
