import React, { useContext, useEffect, useState } from 'react'
import T from 'prop-types'
import slugify from 'slugify'
import { Link as RouterLink } from 'gatsby'
import { useMutation, useQuery, useManualQuery } from 'graphql-hooks'
import { Formik, Form, Field } from 'formik'
import {
  withStyles,
  Button,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TableRow,
  TableCell,
  Typography,
  TextField,
  DialogContent,
  DialogContentText,
  Select,
  Input,
} from '@material-ui/core'
import { TextField as TextFieldFormik } from 'formik-material-ui'
import { DeleteForever, KeyboardArrowUp, Edit } from '@material-ui/icons'
import * as Yup from 'yup'
import { get, sortBy } from 'lodash'

import {
  createOrganizationMutation,
  getOrganizations,
  getGroupsByOrgId,
  deleteOrganizationMutation,
  updateAssessmentGroupIdForOrganizationDeleteMutation,
  updateUserGroupIdForOrganizationDeleteMutation,
  getOrganizationCountOfParentIds,
  updateOrganizationMutation,
} from '../../../queries'

import { ConfirmDialog, GroupTypeChip, ORG_TYPES } from 'gatsby-components'

import useAdminTable from '../../hooks/useAdminTable'

import { AuthContext } from '../authorization/AuthWrapper'
import SelectPicker from './SelectPicker'

let organizationTypeKeys = Object.keys(ORG_TYPES)
let organizationTypes = organizationTypeKeys.map(key => ({
  key,
  value: ORG_TYPES[key],
}))

const OrganizationSchema = Yup.object().shape({
  name: Yup.string().required(),
  type: Yup.string().oneOf(organizationTypeKeys),
})

const headers = [
  { id: 'id', label: 'ID', sortable: true },
  { id: 'name', label: 'Organisation', sortable: true },
  { id: 'type', label: 'Type' },
  {
    id: 'additional-data',
    label: 'Additional data',
    cellProps: {
      align: 'right',
    },
  },
  {
    id: 'delete',
    label: 'Delete',
    cellProps: {
      align: 'right',
    },
  },
]

function UserOrganizations({ whereClause, classes }) {
  const { getUserTokenData, hasPermissions } = useContext(AuthContext)

  const userTokenData = getUserTokenData()
  // =======================================================================================
  // TODO: This component currently uses two forms of the create group mutation; one
  // that requires a parent group ID, and one that doesn't. This is because at the time of
  // merging the auth code from assess-base (AB) and knowledge-base (KB) into the components
  // package, AB had a well-defined concept of parent group whilst KB didn't. Once a proper
  // parent group concept has been worked out for KB, the no-parent version of the mutation
  // can be removed from here and from components/queries/groups and just one createOrganization
  // mutation be used in all cases. Use of the no-parent group mutation must be enabled via
  // the AuthWrapper.
  // =======================================================================================
  const [createOrganization] = useMutation(createOrganizationMutation)
  const [deleteOrganization] = useMutation(deleteOrganizationMutation)
  const [updateAssessmentGroupId] = useMutation(
    updateAssessmentGroupIdForOrganizationDeleteMutation
  )
  const [updateUserOrgAndGroupId] = useMutation(
    updateUserGroupIdForOrganizationDeleteMutation
  )
  const [updateOrganization] = useMutation(updateOrganizationMutation)
  const [getParentIdCount] = useManualQuery(getOrganizationCountOfParentIds)

  // Allow platform-admin and above to see all groups;
  // Limit partner-admin and below to groups under the platform group.
  // const parentId = userTokenData.isPlatformAdmin ? null : userTokenData.groupId

  const [
    organizationDeleteNewOrganizationIdAssessments,
    setOrgIdAssessment,
  ] = useState(false)
  const [
    organizationDeleteNewGroupIdAssessments,
    setOrganizationDeleteNewGroupIdAssessments,
  ] = useState(false)
  const setOrganizationDeleteNewOrganizationIdAssessments = val => {
    setOrganizationDeleteNewGroupIdAssessments(false)
    setOrgIdAssessment(val)
  }

  // states for org edit options
  const [orgCertifiedAs, setOrgCertifiedAs] = useState([])
  const [orgCountry, setOrgCountry] = useState('')
  const [orgWebsite, setOrgWebsite] = useState('')

  const getOrgRes = useQuery(getOrganizations, {
    variables: {
      // parentId,
      orderBy: { id: 'asc' },
    },
  })

  const allOrganizations = get(getOrgRes, 'data.organization', [])
  const allOrganizationsPickerData = sortBy(
    allOrganizations.map(({ id, name }) => ({
      name,
      value: id,
    })),
    o => o.name.toLowerCase()
  )

  const groupPickerData = sortBy(
    allOrganizations
      .filter(({ id }) => id === organizationDeleteNewOrganizationIdAssessments)
      .reduce((a, i) => [...a, ...(i.groups || [])], [])
      .map(({ id, name }) => ({
        name,
        value: id,
      })),
    o => o.name.toLowerCase()
  )

  const [groupDeleteNewOrgIdForUsers, setOrgIdUsers] = useState(false)
  const [
    groupDeleteNewGroupIdForUsers,
    setGroupDeleteNewGroupIdForUsers,
  ] = useState(false)
  const setGroupDeleteNewOrgIdForUsers = val => {
    setGroupDeleteNewGroupIdForUsers(false)
    setOrgIdUsers(val)
  }

  const [groupDeleteErrorMessage, setGroupDeleteErrorMessage] = useState(false)

  const getGroupsRes = useQuery(getGroupsByOrgId, {
    variables: {
      orgId: groupDeleteNewOrgIdForUsers,
    },
  })

  const newUserGroupPickerData = get(
    getGroupsRes,
    'data.group2',
    []
  ).map(({ id, name }) => ({ value: id, name }))

  const canCreateOrganizations = hasPermissions('org-admin')
  const isExactlyOrgAdmin =
    hasPermissions('org-admin') && !userTokenData.isPlatformAdmin

  if (isExactlyOrgAdmin) {
    organizationTypeKeys = organizationTypeKeys.filter(
      key => key !== 'platform'
    )
    organizationTypes = organizationTypes.filter(
      ({ key }) => key !== 'platform'
    )
  }

  const { refetch: refetchOrgs, table } = useAdminTable({
    query: getOrganizations,
    variables: {
      whereClause,
    },
    headers,
    renderTableBody: (data, { refetch: refetchOrgs }) => {
      const doDeleteOrganization = async id => {
        const { data } = await getParentIdCount({
          variables: {
            parentId: id,
          },
        })

        const hasChildGroups =
          get(data, 'organization_aggregate.aggregate.count') > 0

        if (hasChildGroups) {
          setGroupDeleteErrorMessage(
            'The organisation you tried to delete has child organisations, delete them first before proceeding'
          )
          throw Error()
        }

        await updateAssessmentGroupId({
          variables: {
            oldOrganizationId: id,
            newGroupId: organizationDeleteNewOrganizationIdAssessments,
          },
        })

        await updateUserOrgAndGroupId({
          variables: {
            oldOrganizationId: id,
            newOrganizationId: groupDeleteNewOrgIdForUsers,
            newGroupId: groupDeleteNewGroupIdForUsers,
          },
        })

        await deleteOrganization({ variables: { id } })
        refetchOrgs()
      }

      const doUpdateOrganization = id => async () => {
        await updateOrganization({
          variables: {
            organizationId: id,
            certificationTypes: orgCertifiedAs,
            country: orgCountry,
            website: orgWebsite,
            certifiedTrainer: orgCertifiedTrainerName,
            certifiedAdvisor: orgCertifiedAdvisorName,
            certifiedToGive: orgCertifiedToGive,
          },
        })

        refetchOrgs()
      }

      return data.organization.map(organization => (
        <TableRow key={organization.id} data-testid="user-groups" size="small">
          <TableCell>
            <Typography variant="body2">{organization.id}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">
              <RouterLink
                to={`${organization.id}/${slugify(organization.name)}`}
              >
                {organization.name}
              </RouterLink>
            </Typography>
          </TableCell>
          <TableCell>
            <GroupTypeChip status={organization.type} />
          </TableCell>
          <TableCell align="right" padding="none">
            <ConfirmDialog
              title={`Edit organisation “${organization.name}”`}
              onConfirm={doUpdateOrganization(organization.id)}
              okayLabel="Save"
              onOpen={() => {
                setOrgCertifiedAs(organization.certification_types || [])
                setOrgCountry(organization.country || '')
                setOrgWebsite(organization.website || '')
              }}
              waitForAsync
              className={classes.additionalDataConfirmDialog}
              inputElement={
                <DialogContent>
                  <DialogContentText>
                    {'Org certified as (select all that apply)'}
                  </DialogContentText>
                  <Select
                    multiple
                    multiline
                    value={orgCertifiedAs}
                    onChange={e => setOrgCertifiedAs(e.target.value)}
                    input={<Input />}
                    className={classes.certTypeSelect}
                  >
                    {[
                      {
                        key: 'cro',
                        value:
                          'CRO Partners (Certified Recognition Organisations)',
                      },
                    ].map(({ key, value }) => (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                  <DialogContentText>
                    {"Organisation's country"}
                  </DialogContentText>
                  <TextField
                    value={orgCountry}
                    fullWidth
                    onChange={e => setOrgCountry(e.target.value)}
                  />
                  <DialogContentText>{'Website'}</DialogContentText>
                  <TextField
                    value={orgWebsite}
                    fullWidth
                    onChange={e => setOrgWebsite(e.target.value)}
                  />
                </DialogContent>
              }
            >
              <IconButton className={classes.actionButton}>
                <Edit />
              </IconButton>
            </ConfirmDialog>
          </TableCell>
          <TableCell align="right" padding="none">
            {organization.id != userTokenData.orgId && (
              <ConfirmDialog
                title={`Delete organisation “${organization.name}”?`}
                onConfirm={() => doDeleteOrganization(organization.id)}
                okayLabel="Delete"
                submitDisabled={
                  organizationDeleteNewOrganizationIdAssessments === false ||
                  organizationDeleteNewGroupIdAssessments === false ||
                  (groupDeleteNewOrgIdForUsers !== false &&
                    groupDeleteNewGroupIdForUsers === false)
                }
                waitForAsync
                inputElement={
                  <DialogContent>
                    <DialogContentText>
                      {`To which organisation would you like this organisation's old
                      assessments to be reassigned?`}
                    </DialogContentText>
                    <SelectPicker
                      data={allOrganizationsPickerData.filter(
                        ({ value }) => value !== organization.id
                      )}
                      selectedValue={
                        organizationDeleteNewOrganizationIdAssessments
                      }
                      handleChange={val => (
                        setOrganizationDeleteNewOrganizationIdAssessments(val),
                        setGroupDeleteErrorMessage(null)
                      )}
                    />
                    <DialogContentText>
                      {`To which group would you like this organisation's old
                      assessments to be reassigned?`}
                    </DialogContentText>
                    <SelectPicker
                      data={groupPickerData}
                      selectedValue={organizationDeleteNewGroupIdAssessments}
                      handleChange={val => (
                        setOrganizationDeleteNewGroupIdAssessments(val),
                        setGroupDeleteErrorMessage(null)
                      )}
                    />
                    <DialogContentText className={classes.userGroupSelectTitle}>
                      {
                        'Users can be reassigned or they can be left unassigned (new Organisation for user)'
                      }
                    </DialogContentText>
                    <SelectPicker
                      data={[
                        { value: false, name: 'Leave users unassigned' },
                        ...allOrganizationsPickerData,
                      ].filter(({ value }) => value !== organization.id)}
                      selectedValue={groupDeleteNewOrgIdForUsers}
                      handleChange={val => (
                        setGroupDeleteNewOrgIdForUsers(val),
                        setGroupDeleteErrorMessage(null)
                      )}
                    />
                    {groupDeleteNewOrgIdForUsers !== false && (
                      <>
                        <DialogContentText
                          className={classes.userGroupSelectTitle}
                        >
                          New group for user
                        </DialogContentText>
                        <SelectPicker
                          data={newUserGroupPickerData}
                          selectedValue={groupDeleteNewGroupIdForUsers}
                          handleChange={val => (
                            setGroupDeleteNewGroupIdForUsers(val),
                            setGroupDeleteErrorMessage(null)
                          )}
                        />
                      </>
                    )}
                    {!!groupDeleteErrorMessage && (
                      <DialogContentText color="error">
                        {groupDeleteErrorMessage}
                      </DialogContentText>
                    )}
                    {!allOrganizationsPickerData.filter(
                      ({ value }) => value !== organization.id
                    ).length && (
                      <DialogContentText color="error">
                        Before deleting this group, your assessments must have a
                        new group to which they can be assigned. Please create a
                        new group and then try again.
                      </DialogContentText>
                    )}
                  </DialogContent>
                }
                onCancel={() => (
                  setOrganizationDeleteNewOrganizationIdAssessments(false),
                  setOrganizationDeleteNewGroupIdAssessments(false),
                  setGroupDeleteNewOrgIdForUsers(false),
                  setGroupDeleteNewGroupIdForUsers(false),
                  setGroupDeleteErrorMessage(null)
                )}
              >
                <IconButton className={classes.actionButton}>
                  <DeleteForever />
                </IconButton>
              </ConfirmDialog>
            )}
          </TableCell>
        </TableRow>
      ))
    },
  })

  return (
    <>
      {canCreateOrganizations && (
        <Formik
          validationSchema={OrganizationSchema}
          initialValues={{
            name: '',
            type: organizationTypes[0].key,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const { orgId: parentId } = userTokenData
              await createOrganization({
                variables: { ...values, parentId },
              })
              refetchOrgs()
            } finally {
              setSubmitting(false)
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Grid
                container
                alignItems="flex-end"
                spacing={2}
                className={classes.newGroup}
              >
                <Grid item xs={3}>
                  <InputLabel htmlFor="organization-name-input">
                    <Typography
                      variant="h4"
                      gutterBottom
                      className={classes.inputLabel}
                    >
                      Enter new organisation name
                    </Typography>
                  </InputLabel>
                  <Field
                    id="organization-name-input"
                    component={TextFieldFormik}
                    name="name"
                    placeholder="name"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel htmlFor="organization-type-input">
                    <Typography
                      variant="h4"
                      gutterBottom
                      className={classes.inputLabel}
                    >
                      Select organisation type
                    </Typography>
                  </InputLabel>
                  <Field
                    id="organization-type-input"
                    component={TextFieldFormik}
                    select
                    name="type"
                    fullWidth
                    SelectProps={{
                      classes: {
                        root: classes.selectRoot,
                        select: classes.selectElem,
                        icon: classes.selectIcon,
                      },
                      IconComponent: KeyboardArrowUp,
                    }}
                  >
                    {organizationTypes.map(type => (
                      <MenuItem key={type.key} value={type.key}>
                        {type.value}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    Create organisation
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
      {table}
    </>
  )
}
UserOrganizations.propTypes = {
  whereClause: T.object,
  classes: T.object.isRequired,
}

const styles = theme => ({
  newGroup: {
    marginBottom: theme.spacing(4),
  },
  actionButton: {
    marginRight: theme.spacing(2),
  },
  copyLinkButton: {
    padding: theme.spacing(0.5, 1),
    border: `1px solid ${theme.palette.secondary.main}`,
    '&:hover': {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },
  copyIcon: {
    marginRight: theme.spacing(1),
  },
  // TODO: Move these to a generic field component shared with modal, criterion part, etc
  inputLabel: {
    color: theme.palette.primary.dark,
    fontWeight: 700,
    marginTop: theme.spacing(1),
  },
  additionalDataConfirmDialog: {
    '& .MuiDialogContentText-root': {
      marginTop: theme.spacing(2),
    },
  },
  selectRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  selectElem: {
    flexGrow: 1,
  },
  selectIcon: {
    color: theme.palette.secondary.main,
  },
  userGroupSelectTitle: {
    marginTop: theme.spacing(5),
  },
  certTypeSelect: {
    textOverflow: 'ellipsis',
    width: '300px',
  },
})

export default withStyles(styles)(UserOrganizations)
