export function getAssessmentId(location) {
  const id = parseInt(location.hash.replace('#', ''), 10)
  return isNaN(id) ? null : id
}

export function addParamsToUrl(url, params) {
  Object.keys(params).forEach((key, i) => {
    url += `${i ? '&' : '?'}${key}=${params[key]}`
  })
  return url
}
