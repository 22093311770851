import React from 'react'
import T from 'prop-types'
import get from 'lodash/get'
import { Box, makeStyles } from '@material-ui/core'
import { Link } from 'gatsby'
import { ThumbnailImage } from 'gatsby-components'

import { constructImageUrl } from 'gatsby-components/src/utils/image'

const useLinkStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  textBox: ({ paletteColor }) => ({
    ...theme.typography.h3,
    backgroundColor: get(
      theme.palette,
      paletteColor,
      theme.palette.primary.main
    ),
    color: 'white',
    marginBottom: '0',
    marginTop: '-33px',
    padding: theme.spacing(2),
    width: '85%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
}))

const ArticleVisualSummary = ({
  thumbnail,
  className = '',
  link,
  text,
  paletteColor,
  imageIsNotFromS3,
}) => {
  const { textBox, wrapper } = useLinkStyles({ paletteColor })

  const WrapperComponent = link ? Link : Box

  return (
    <WrapperComponent to={link} className={`${className} ${wrapper}`}>
      <ThumbnailImage
        height={140}
        path={imageIsNotFromS3 ? thumbnail : constructImageUrl(thumbnail)}
      />
      {text && (
        <Box className={textBox} component="p">
          {text}
        </Box>
      )}
    </WrapperComponent>
  )
}

ArticleVisualSummary.propTypes = {
  thumbnail: T.string,
  link: T.string,
  text: T.string,
  className: T.string, // Allow a class to come in from the parent
  paletteColor: T.arrayOf(T.string),
  imageIsNotFromS3: T.bool,
}

export default ArticleVisualSummary
