import React, { useState } from 'react'
import T from 'prop-types'
import {
  withStyles,
  TableRow,
  TableCell,
  IconButton,
  Grid,
  Typography,
  Button,
} from '@material-ui/core'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn'
import { useMutation } from 'graphql-hooks'
import { getAssessmentTypes } from 'efqm-theme/assessments/getAssessmentParts'

import { AssessmentStatusChip, ConfirmDialog } from 'gatsby-components'
import { getAssessmentsData, deleteAssessmentMutation } from '../queries'
import { formatLocalizedDate } from 'gatsby-components/src/utils/date'
import QueryTable from './QueryTable'
import AssessmentsTableCopyButton from './AssessmentsTableCopyButton'
import AssessmentsTableMoveButton from './AssessmentsTableMoveButton'
import { FeedbackReportLink, ManagementReportLink } from './report-links'

function AssessmentsTable({
  classes,
  isAdmin,
  isPlatformAdmin,
  whereClause,
  isInAdminArea,
}) {
  const { t, i18n } = useTranslation()
  const lang = i18n.language || 'en'

  const assessmentTypes = getAssessmentTypes(i18n).reduce(
    (a, assessmentType) => {
      if (assessmentType.type === 'assessment') {
        return {
          ...a,
          [assessmentType.key]: {
            key: assessmentType.key,
            name: assessmentType.name,
            hideManagementReport: assessmentType.hideManagementReport,
            showFeedbackReport: assessmentType.showFeedbackReport,
          },
        }
      }

      if (assessmentType.type === 'lens') {
        const result = { ...a }

        assessmentType.assessmentTypes.forEach(
          ({ key, name, hideManagementReport, showFeedbackReport }) => {
            result[key] = {
              key,
              name,
              hideManagementReport,
              showFeedbackReport,
            }
          }
        )

        return result
      }
    },
    {}
  )

  const [deleteAssessment] = useMutation(deleteAssessmentMutation)
  const [filterAssessments, setFilterAssessments] = useState([])

  const headers = [
    {
      id: 'name',
      label: t('Your assessments'),
      sortable: true,
      cellProps: { width: '242px' },
    },
    {
      id: 'date-status',
      label: t('Date'),
      cellProps: { width: '116px' },
    },
    {
      id: 'reports',
      label: t('Reports'),
      cellProps: { width: '248px' },
    },
    {
      id: 'organizations-and-groups',
      label: t('Organisation & Group'),
      cellProps: { width: '209px' },
    },
  ]
  if (isAdmin) {
    headers.push(
      {
        id: 'copy',
        label: t('Copy'),
        cellProps: { width: '68px' },
      },
      {
        id: 'move',
        label: t('Move'),
        cellProps: { width: '68px' },
      },
      {
        id: 'delete',
        label: t('Delete'),
        cellProps: { width: '68px' },
      }
    )
  } else {
    headers[0].cellProps.width = `${290 + 68 + 68}px`
  }

  const onRowClick = (key, id) => () => {
    navigate(`/assessment/${key}#${id}`)
  }

  const onDelete = (id, refetch) => async () => {
    if (
      (
        await deleteAssessment({
          variables: {
            assessmentId: id,
          },
        })
      ).error === false
    ) {
      setFilterAssessments([...filterAssessments, id])
    }

    refetch()
  }

  return (
    <QueryTable
      headers={headers}
      query={getAssessmentsData}
      orderBy={{ created_at: 'desc' }}
      variables={{ whereClause }}
      className={classnames(classes.table, {
        [classes.adminAreaSetWidth]: isInAdminArea,
      })}
      renderTableBody={({ data, refetch }) =>
        data.assessment
          .filter(({ id }) => !filterAssessments.includes(id))
          .map((assessment, index) => {
            const {
              id,
              key,
              name: title,
              created_at,
              status,
              internal,
            } = assessment
            const onClick = onRowClick(key, id)

            const showManagementReport = !assessmentTypes[key]
              .hideManagementReport
            const showFeedbackReport =
              status === 'submitted' ||
              status === 'closed' ||
              assessmentTypes[key].showFeedbackReport

            return (
              <TableRow hover key={index} size="small" className={classes.row}>
                <TableCell
                  width={headers[0]?.cellProps?.width}
                  data-testid="assessment-table-name"
                  onClick={onClick}
                  className={classnames(classes.cells, classes.rightCellBorder)}
                >
                  <Grid container direction="column">
                    <Grid item>
                      <Typography
                        variant="h4"
                        className={classes.assessmentType}
                      >
                        {`${assessmentTypes[key].name}${
                          internal ? ' (internal)' : ''
                        }`}
                      </Typography>
                    </Grid>
                    <Grid item className={classes.assessmentName}>
                      {title}
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell
                  colSpan={headers[1]?.cellProps?.colSpan}
                  width={headers[1]?.cellProps?.width}
                  data-testid="assessment-table-date"
                  onClick={onClick}
                  className={classnames(
                    classes.rightCellBorder,
                    classes.dateCell
                  )}
                >
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item>
                      {formatLocalizedDate(
                        created_at,
                        lang === 'en' ? 'en-GB' : lang
                      )}
                    </Grid>
                    <Grid item>
                      <AssessmentStatusChip showIcon={false} status={status} />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell
                  colSpan={headers[2]?.cellProps?.colSpan}
                  width={headers[2]?.cellProps?.width}
                  data-testid="assessment-table-links"
                  className={classnames(
                    classes.cells,
                    classes.rightCellBorder,
                    classes.reportsCell
                  )}
                >
                  <Grid
                    container
                    justify={
                      showManagementReport && showFeedbackReport
                        ? 'space-between'
                        : 'flex-start'
                    }
                    alignItems="center"
                    className={classes.reportLinksGrid}
                  >
                    <Grid item style={{ width: '5px' }} />
                    {showManagementReport && (
                      <Grid item className={classes.reportLinks}>
                        <ManagementReportLink
                          assessment={assessment}
                          spacing={1}
                          text={t('Management')}
                        />
                      </Grid>
                    )}
                    {showFeedbackReport && (
                      <Grid item className={classes.reportLinks}>
                        <FeedbackReportLink
                          assessment={assessment}
                          visible
                          spacing={1}
                          text={t('Feedback')}
                        />
                      </Grid>
                    )}
                    <Grid item style={{ width: '5px' }} />
                  </Grid>
                </TableCell>
                <TableCell
                  colSpan={headers[3]?.cellProps?.colSpan}
                  width={headers[3]?.cellProps?.width}
                  data-testid="assessment-table-links"
                  className={classnames(classes.cells, classes.rightCellBorder)}
                >
                  <Grid
                    container
                    direction="column"
                    className={classes.groupOrgContainerGrid}
                  >
                    <Grid item>{assessment?.group?.organization?.name}</Grid>
                    <Grid container item alignItems="center">
                      <KeyboardReturnIcon className={classes.groupIcon} />
                      {assessment?.group?.name}
                    </Grid>
                  </Grid>
                </TableCell>
                {isAdmin && (
                  <>
                    <TableCell
                      colSpan={headers[4]?.cellProps?.colSpan}
                      width={headers[4]?.cellProps?.width}
                      padding="none"
                      data-testid="assessment-table-duplicate"
                      align="center"
                      className={classnames(
                        classes.cells,
                        classes.adminToolButtons
                      )}
                    >
                      <AssessmentsTableCopyButton
                        assessment={assessment}
                        isPlatformAdmin={isPlatformAdmin}
                      />
                    </TableCell>
                    <TableCell
                      colSpan={headers[5]?.cellProps?.colSpan}
                      width={headers[5]?.cellProps?.width}
                      padding="none"
                      data-testid="assessment-table-move"
                      align="center"
                      className={classnames(
                        classes.cells,
                        classes.adminToolButtons
                      )}
                    >
                      {!!assessment.group && (
                        <AssessmentsTableMoveButton
                          assessment={assessment}
                          onSubmit={() => refetch()}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      colSpan={headers[6]?.cellProps?.colSpan}
                      width={headers[6]?.cellProps?.width}
                      padding="none"
                      data-testid="assessment-table-delete"
                      align="center"
                      className={classnames(
                        classes.cells,
                        classes.adminToolButtons
                      )}
                    >
                      <ConfirmDialog
                        onConfirm={onDelete(id, refetch)}
                        title={t('Delete assessment', {
                          assessmentTitle: title,
                        })}
                        text={t(
                          'This assessment will be deleted. This cannot be undone.'
                        )}
                      >
                        <IconButton component={Button}>
                          <DeleteForeverIcon />
                        </IconButton>
                      </ConfirmDialog>
                    </TableCell>
                  </>
                )}
              </TableRow>
            )
          })
      }
    />
  )
}

AssessmentsTable.propTypes = {
  isAdmin: T.bool,
  isPlatformAdmin: T.bool,
  whereClause: T.object,
  classes: T.object,
}

const styles = theme => ({
  adminAreaSetWidth: {
    width: '1024px',
  },
  table: {
    overflowY: 'scroll',
    '& .MuiTable-root': {
      tableLayout: 'fixed',
    },
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
    '& .MuiTableRow-root': {
      borderBottom: 'solid 1px #b7c9dc',
    },
    '& .MuiTableRow-head': {
      borderBottom: `solid 1px ${theme.palette.background.light}`,
      padding: theme.spacing(100, 10),
    },
  },
  cells: {
    borderBottom: 'none',
    padding: 0,
    '& > *': {
      padding: theme.spacing(0, 2),
    },
  },
  reportsCell: {
    '& > *': {
      margin: theme.spacing(0, 2),
      padding: theme.spacing(0),
    },
  },
  rightCellBorder: {
    background: `linear-gradient(to bottom, transparent calc(100% - 8px), ${theme.palette.background.default} 8px),
      linear-gradient(to top, transparent calc(100% - 8px), ${theme.palette.background.default} 8px),
      linear-gradient(to right, transparent calc(100% - 1px), #b7c9dc 1px)`,
  },
  dateCell: {
    paddingLeft: theme.spacing(1.25),
  },
  assessmentType: {
    color: '#9298b5',
  },
  assessmentName: {
    fontSize: '16px',
  },
  cellPadding: {
    height: theme.spacing(1),
  },
  groupOrgContainerGrid: {
    marginLeft: theme.spacing(1),
  },
  reportLinksGrid: {
    margin: 0,
  },
  adminToolButtons: {
    '& > span': {
      marginLeft: theme.spacing(1),
    },
  },
  row: {
    '& .titleChip': {
      backgroundColor: theme.palette.background.paper,
      height: 'auto',
      marginLeft: '-8px',
      '& .MuiSvgIcon-root': {
        color: theme.palette.background.paper,
      },
      '& .MuiChip-label': {
        display: 'flex',
        alignItems: 'center',
        '& > * + * ': {
          marginLeft: theme.spacing(1),
        },
      },
    },
    '&:hover': {
      '& .titleChip': {
        backgroundColor: '#e4f5f4',
        '& .MuiSvgIcon-root': {
          color: theme.palette.secondary.main,
        },
      },
    },
    '& .MuiButtonBase-root': {
      minWidth: 0,
      color: theme.palette.tertiary ? theme.palette.tertiary.main : null,
      marginLeft: '-12px',
    },
  },
  reportLinks: {
    backgroundColor: '#f1f4f6',
    borderRadius: '3px',
    padding: theme.spacing(0.5, 0.75, 0.5, 0.25),
    '& .MuiTypography-root': {
      ...theme.typography.body2,
      color: theme.palette.primary.dark,
    },
  },
  nonApplicableReport: {
    // only way to match chevron color as it does not exist in theme
    color: 'rgba(0, 0, 0, 0.54)',
    marginLeft: '20px',
  },
  groupIcon: {
    color: theme.palette.secondary.main,
    '-moz-transform': 'scale(-1, 1)',
    '-webkit-transform': 'scale(-1, 1)',
    '-o-transform': 'scale(-1, 1)',
    '-ms-transform': 'scale(-1, 1)',
    transform: 'scale(-1, 1)',
  },
})

export default withStyles(styles)(AssessmentsTable)
