const theme = require('./theme')
const metadata = require('./metadata')
const config = require('./config')
const assessments = require('./assessments')

module.exports = {
  theme,
  metadata,
  config,
  assessments,
}
