// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-auth-js": () => import("./../../../src/pages/auth.js" /* webpackChunkName: "component---src-pages-auth-js" */),
  "component---src-pages-content-js": () => import("./../../../src/pages/content.js" /* webpackChunkName: "component---src-pages-content-js" */),
  "component---src-pages-my-content-js": () => import("./../../../src/pages/my-content.js" /* webpackChunkName: "component---src-pages-my-content-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-content-js": () => import("./../../../src/templates/content.js" /* webpackChunkName: "component---src-templates-content-js" */),
  "component---src-templates-content-list-js": () => import("./../../../src/templates/content-list.js" /* webpackChunkName: "component---src-templates-content-list-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-profile-js": () => import("./../../../src/templates/profile.js" /* webpackChunkName: "component---src-templates-profile-js" */)
}

