/* eslint-disable react/prop-types */
// prop-types were missing prior to trasfer to components. todo
import React, { useState } from 'react'
import T from 'prop-types'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Tooltip,
  TableSortLabel,
  Typography,
  Paper,
} from '@material-ui/core'
import { useQuery } from 'graphql-hooks'
import { useTranslation } from 'react-i18next'

function QueryTable({
  headers,
  pageSizes = [10, 20, 50],
  startingPageSize,
  renderTableBody,
  orderBy: orderByProp = { id: 'asc' },
  query,
  _DUMMY_DATA, // only to be used until partners accounts are made and properly stored in DB
  _DUMMY_SEARCH_TEXT,
  variables,
  className,
  testid,
}) {
  const { t } = useTranslation()
  const [offset, setOffset] = useState(0)
  const [pageSize, setPageSize] = useState(startingPageSize || pageSizes[0])
  const [orderBy, setOrderBy] = useState(orderByProp)

  // start of temp
  const dummy = _DUMMY_DATA
    ? JSON.parse(JSON.stringify(_DUMMY_DATA))
    : undefined

  const regex = new RegExp(_DUMMY_SEARCH_TEXT, 'i')
  let totalCount = 0

  if (dummy && dummy.organization) {
    dummy.organization = dummy.organization.filter(obj =>
      Object.values(obj).some(str => str.match(regex))
    )
    totalCount = dummy.organization.length
    dummy.organization = dummy.organization.slice(offset, pageSize + offset)
  }
  if (dummy && dummy.user) {
    dummy.user = dummy.user.filter(obj =>
      Object.values(obj).some(
        str =>
          (typeof str === 'string' && str.match(regex)) ||
          Object.values(str).some(str => str.match(regex))
      )
    )
    totalCount = dummy.user.length
    dummy.user = dummy.user.slice(offset, pageSize + offset)
  }

  headers = headers.map(x => ({ ...x, sortable: false }))

  //end of temp DELETE ALL IN BETWEEN AND CLEAN UP REST OF FILES

  const { loading, error, data, refetch } = dummy
    ? { data: dummy }
    : useQuery(query, {
        updateData: (_, nextData) => nextData, //fixes pagination flashing
        variables: {
          ...variables,
          offset,
          limit: pageSize,
          orderBy,
        },
      })

  if (!headers.length)
    return (
      <Typography>
        {t('queryTable.noTableHeaders', 'No table headers to show')}
      </Typography>
    )

  if (!data && loading)
    return <Typography>{t('common.loading', 'Loading...')}</Typography>
  if (error)
    return (
      <Typography>
        {t('queryTable.error.loading', 'Error loading data.')}
      </Typography>
    )

  return (
    <Paper data-testid={testid} className={className}>
      {data && (
        <Table>
          <TableHead>
            <TableRow>
              {headers.map(({ id, sortable, cellProps, label }) => {
                if (!sortable) {
                  return (
                    <TableCell key={id} {...cellProps}>
                      {label}
                    </TableCell>
                  )
                }
                const columnIsOrdered = orderBy.hasOwnProperty(id)
                return (
                  <TableCell
                    key={id}
                    sortDirection={columnIsOrdered ? orderBy[id] : false}
                    {...cellProps}
                  >
                    <Tooltip title={t('common.sort', 'Sort')} enterDelay={300}>
                      <TableSortLabel
                        active={columnIsOrdered}
                        direction={orderBy[id]}
                        onClick={() =>
                          setOrderBy({
                            [id]: orderBy[id] === 'asc' ? 'desc' : 'asc',
                          })
                        }
                      >
                        {label}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>{renderTableBody({ data, refetch })}</TableBody>
          <TableFooter>
            <TableRow>
              {_DUMMY_DATA ? (
                <TablePagination
                  rowsPerPageOptions={pageSizes}
                  colSpan={headers.length}
                  count={totalCount}
                  rowsPerPage={pageSize}
                  page={Math.floor(offset / pageSize)}
                  onChangePage={(event, page) => setOffset(page * pageSize)}
                  onChangeRowsPerPage={event => setPageSize(event.target.value)}
                /> // TODO: to be removes when dummy data is removed from home-base
              ) : (
                <TablePagination
                  rowsPerPageOptions={pageSizes}
                  colSpan={headers.length}
                  count={data.field_aggregate.aggregate.count}
                  rowsPerPage={pageSize}
                  page={Math.floor(offset / pageSize)}
                  onChangePage={(event, page) => setOffset(page * pageSize)}
                  onChangeRowsPerPage={event => setPageSize(event.target.value)}
                />
              )}
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </Paper>
  )
}

QueryTable.propTypes = {
  headers: T.arrayOf(
    T.shape({
      id: T.string.isRequired,
      label: T.string.isRequired,
      sortable: T.bool,
      cellProps: T.object,
    })
  ).isRequired,
  pageSizes: T.arrayOf(T.number),
  startingPageSize: T.number,
  renderTableBody: T.func.isRequired,
  testid: T.string,
  _DUMMY_DATA: T.array,
}

export default QueryTable
