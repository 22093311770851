import React, { useState } from 'react'
import T from 'prop-types'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Tooltip,
  TableSortLabel,
  Typography,
  Paper,
} from '@material-ui/core'
import { useQuery } from 'graphql-hooks'
import { useTranslation } from 'react-i18next'

function QueryTable({
  headers,
  pageSizes = [10, 20, 50],
  renderTableBody,
  orderBy: orderByProp = { id: 'asc' },
  query,
  variables: variablesProp,
  testid,
  ...props
}) {
  const [offset, setOffset] = useState(0)
  const [pageSize, setPageSize] = useState(pageSizes[0])
  const [orderBy, setOrderBy] = useState(orderByProp)
  const { t } = useTranslation()

  const variables = {
    ...variablesProp,
    offset,
    limit: pageSize,
    orderBy,
  }
  const { loading, error, data, refetch } = useQuery(query, {
    variables,
  })

  const resetOffset = () => setOffset(0)

  if (!headers.length)
    return <Typography>{t('No table headers to show')}</Typography>

  if (loading) return <Typography>{t('Loading...')}</Typography>

  if (error) return <Typography>{t('Error loading data')}</Typography>

  return (
    <Paper data-testid={testid} {...props}>
      {data && (
        <Table>
          <TableHead>
            <TableRow>
              {headers.map(
                ({ id, sortable, cellProps, label, includeInPage = true }) => {
                  if (!includeInPage) return

                  if (!sortable) {
                    return (
                      <TableCell
                        key={id}
                        {...cellProps}
                        data-testid="assessment-table-titles"
                      >
                        {label}
                      </TableCell>
                    )
                  }
                  const columnIsOrdered = orderBy.hasOwnProperty(id)
                  return (
                    <TableCell
                      key={id}
                      sortDirection={columnIsOrdered ? orderBy[id] : false}
                      data-testid="assessment-table-titles"
                      {...cellProps}
                    >
                      <Tooltip title="Sort" enterDelay={300}>
                        <TableSortLabel
                          active={columnIsOrdered}
                          direction={orderBy[id]}
                          onClick={() =>
                            setOrderBy({
                              [id]: orderBy[id] === 'asc' ? 'desc' : 'asc',
                            })
                          }
                        >
                          {label}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  )
                }
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {renderTableBody({ data, variables, resetOffset, refetch })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                data-testid="assessment-table-footer"
                rowsPerPageOptions={pageSizes}
                colSpan={headers.length}
                count={data.field_aggregate.aggregate.count}
                rowsPerPage={pageSize}
                page={Math.floor(offset / pageSize)}
                onChangePage={(event, page) => setOffset(page * pageSize)}
                onChangeRowsPerPage={event => setPageSize(event.target.value)}
              />
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </Paper>
  )
}

QueryTable.propTypes = {
  headers: T.arrayOf(
    T.shape({
      id: T.string.isRequired,
      label: T.string.isRequired,
      sortable: T.bool,
      cellProps: T.object,
    })
  ).isRequired,
  pageSizes: T.arrayOf(T.number),
  renderTableBody: T.func.isRequired,
  testid: T.string,
}

export default QueryTable
