import T from 'prop-types'
import get from 'lodash/get'
import { assessments } from '../../../theme.es'

const SLIDER_STEP = 10

const getPointScore = (percentScore, weight, possiblePoints) =>
  Math.round((percentScore * weight * possiblePoints) / 100)

function getOverallScore(chartData) {
  return chartData.reduce((acc, dataItem) => acc + dataItem.pointScore, 0)
}

function calculatePartsMean(scores, weightValues) {
  if (!scores.length) return 0
  if (weightValues) {
    const sumOfWeightValues = weightValues.reduce((a, x) => a + x, 0)

    return scores.reduce(
      (sum, { score }, i) =>
        sum + score * (weightValues[i] / sumOfWeightValues),
      0
    )
  }
  return scores.reduce((sum, { score }) => sum + score, 0) / scores.length
}

function getChartData(assessmentDef, assessmentData, pillarColors) {
  /**
   * Collates hierarchical data, calculating weighted average "score" at each level:
   * - Criterion (color per pillar)           e.g. "Purpose & Strategy", "Execution"
   *   - Criterion-part                       Takes names from criterion part's tables
   *     # (skips this level) - Scoring group e.g. "Relevance & Usability", "Performance"
   *       - Score from one scoring slider    e.g. "Scope & Relevance", "Integrity"
   *
   * TODO: Optimise and simplify when sure that business logic is sound.
   */
  if (!assessmentData) return null

  return assessmentDef.pillars.reduce((chartData, pillarDef, pillarIndex) => {
    const { key: pillarKey, criteria: criteriaDef } = pillarDef
    if (!assessmentData.scoring) return chartData

    const pillarColor = pillarColors[pillarIndex]
    const pillarScores = assessmentData.scoring.filter(
      scoresByPillar => scoresByPillar.pillar_key === pillarKey
    )

    criteriaDef.forEach(criterionDef => {
      const scoringDef =
        criterionDef.scoring || pillarDef.scoring || assessmentDef.scoring
      const scoringRules =
        criterionDef.scoringRules ||
        pillarDef.scoringRules ||
        assessmentDef.scoringRules ||
        {}

      let criterionPartWeighting = (
        assessmentData.criterion_weightings || []
      ).find(weighting => weighting.criterion_key === criterionDef.key)

      if (criterionPartWeighting) {
        criterionPartWeighting = criterionPartWeighting.weighting_values
      }

      chartData.push(
        getScoresByCriteria(
          criterionDef,
          pillarScores,
          pillarKey,
          scoringDef,
          scoringRules,
          pillarColor,
          assessmentDef.key,
          criterionPartWeighting
        )
      )
    })

    return chartData
  }, [])
}

function getScoresByCriteria(
  criterionDef,
  pillarScores,
  pillarKey,
  scoringDef,
  scoringRules,
  pillarColor,
  assessmentKey,
  criterionPartWeighting
) {
  const {
    name: criterionName,
    key: criterionKey,
    parts: criterionPartsDefs,
  } = criterionDef

  const scoreValuesByCriterion = pillarScores.filter(
    criterionPartScore => criterionPartScore.criterion_key === criterionKey
  )

  const {
    criteriaWeighting,
    [assessmentKey]: scoringPoints,
  } = assessments[0].scoring

  const compositeKey = `${pillarKey}_${criterionKey}`
  const criterionPath = `assessment/${assessmentKey}/${pillarKey}/${criterionKey}`

  const chartDataByCriterionParts = scoreValuesByCriterion
    .map(scoreValuesByCriterionPart =>
      getScoresByCriterionPart(
        scoreValuesByCriterionPart,
        criterionPartsDefs,
        scoringDef,
        scoringRules,
        compositeKey,
        criterionPath,
        scoringPoints
      )
    )
    .sort((a, b) => (a.label > b.label && 1) || (a.label < b.label && -1) || 0)

  const criteriaScore = criterionPartWeighting
    ? calculatePartsMean(
        chartDataByCriterionParts,
        scoreValuesByCriterion
          .sort((a, b) => a.part_number - b.part_number)
          .map(({ part_number: partNumber }) => {
            const partKey = criterionDef.parts[partNumber - 1].weight.key
            return (
              criterionPartWeighting[partKey] /
              criterionDef.weightRules.totalsTo
            )
          })
      )
    : calculatePartsMean(chartDataByCriterionParts)

  const pointCriteriaScore = getPointScore(
    criteriaScore,
    criteriaWeighting[criterionKey] || 1,
    scoringPoints
  )

  return {
    key: compositeKey,
    label: criterionName,
    color: pillarColor,
    scores: chartDataByCriterionParts,
    score: criteriaScore,
    pointScore: pointCriteriaScore,
    weighting: criteriaWeighting[criterionKey] || 1,
  }
}

function getScoresByCriterionPart(
  scoreValuesByCriterionPart,
  criterionPartsDefs,
  scoringDef,
  scoringRules,
  previousKey,
  criterionPath,
  scoringPoints
) {
  const {
    part_number: partNumber,
    scoring_values: scoringValues,
  } = scoreValuesByCriterionPart
  const { tables: partTablesDef } = criterionPartsDefs[partNumber - 1]
  const partScoringPoints = scoringPoints / partTablesDef.length

  const cap = roundBySliderStep(get(scoringValues, scoringRules.capBy, 100))

  const scoresByScoringItems = scoringDef.reduce(
    (scoringData, scoringGroupDef) =>
      scoringValues[scoringGroupDef.key]
        ? [
            ...scoringData,
            ...getScoresFromScoringGroups(
              scoringValues[scoringGroupDef.key],
              scoringGroupDef.scores
            ),
          ]
        : scoringData,
    []
  )

  const rawScore = roundBySliderStep(
    // each scoring group gets it's own average, then the overall score is calculated bases on the average of the scoring group averages
    Object.values(scoringValues).reduce(
      (a, scores, i, { length }) =>
        (a +
          Object.values(scores).reduce(
            (a, score, i, { length }) =>
              (a + score) / (i === length - 1 ? length : 1),
            0
          )) /
        (i === length - 1 ? length : 1),
      0
    )
  )

  const score = cap < rawScore ? cap : rawScore

  const pointScore = getPointScore(score, 1, partScoringPoints)

  return {
    key: `${previousKey}_${partNumber}`,
    label: partTablesDef.map(table => table.name).join(', '),
    scores: scoresByScoringItems,
    score,
    pointScore,
    path: `${criterionPath}/${partNumber}`,
  }
}

function roundBySliderStep(num) {
  return Math.round(num / SLIDER_STEP) * SLIDER_STEP
}

function getScoresFromScoringGroups(scoringValues, def) {
  return Object.entries(scoringValues).map(([key, score]) => {
    const scoreDef = def.find(scoreDef => scoreDef.key === key)
    /* Scoring Groups are percentage-based scores used, in aggregate, to generate
     * point-value scores for SubCriteria. They do not have point-value scores of
     * their own. The pointScore is therefore always set to a placeholder: '--'
     */
    return {
      score: roundBySliderStep(score),
      pointScore: '--',
      key,
      label: scoreDef ? scoreDef.name : '',
    }
  })
}

const chartDataShape = T.shape({
  score: T.number.isRequired,
  color: T.string,
  weighting: T.number,
  label: T.string.isRequired,
  key: T.string.isRequired,
})

function questionnaireScoreBundling(pillarDef, scoresData) {
  if (!scoresData.length) return []

  return pillarDef.scores.map(scoreDef => {
    const scores = scoresData.map(score => ({
      key: `${pillarDef.key}_${scoreDef.key}_${score.user_id}`,
      label: scoreDef.description,
      score: score.scoring_values[pillarDef.key][scoreDef.key],
    }))

    return {
      key: `${pillarDef.key}_${scoreDef.key}`,
      label: scoreDef.description,
      scores,
      score: Math.round(
        scores.reduce((a, { score }) => a + score, 0) / scores.length
      ),
    }
  })
}

function getQuestionnaireChartData(
  assessmentDef,
  assessmentData,
  pillarColors
) {
  const SLIDER_MAX = 100
  const {
    pillarWeightingQuestionnaire,
    ['questionnaire-2020']: scoringPoints,
  } = assessments[0].scoring

  const weightingSum = Object.values(pillarWeightingQuestionnaire).reduce(
    (a, x) => a + x,
    0
  )

  const chartData = assessmentDef.pillars.map((pillar, i) => {
    const scores = questionnaireScoreBundling(
      pillar,
      assessmentData.questionnaire_scores.filter(
        ({ pillar_key: pillarKey }) => pillarKey === pillar.key
      )
    )

    const weighting = pillarWeightingQuestionnaire[pillar.key]

    const score = scores.length
      ? Math.round(
          scores.reduce((a, { score }) => a + score, 0) / scores.length
        )
      : 0

    return {
      key: pillar.key,
      label: pillar.name,
      color: pillarColors[i],
      scores,
      score,
      pointScore: Math.round(
        (score / SLIDER_MAX) * (scoringPoints * (weighting / weightingSum))
      ),
      weighting,
    }
  })

  return chartData
}

export {
  getOverallScore,
  chartDataShape,
  getChartData,
  getQuestionnaireChartData,
}
