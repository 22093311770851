module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"EFQM Knowledge Base","short_name":"EFQM-AB","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"/home/circleci/project/packages/efqm-theme/assets/logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"119aa096f280cd0d6a55254dc8fb2c7b"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
