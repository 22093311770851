import React, { useState } from 'react'
import { withStyles, Button, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useManualQuery } from 'graphql-hooks'
import get from 'lodash/get'

import { getNonUserQuestionnairesData } from '../../queries'
import { getAssessmentParts } from 'efqm-theme/assessments/getAssessmentParts'
import { getQuestionnaireChartData } from 'gatsby-components'
import { getContextDataForChartFunc } from '../utils'

function exportToCsv(filename, rows) {
  // taken from: https://stackoverflow.com/a/24922761
  var processRow = function(row) {
    var finalVal = ''
    for (var j = 0; j < row.length; j++) {
      var innerValue = !row[j] ? '' : row[j].toString()
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString()
      }
      var result = innerValue.replace(/"/g, '""')
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"'
      if (j > 0) finalVal += ','
      finalVal += result
    }
    return finalVal + '\n'
  }

  var csvFile = ''
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i])
  }

  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename)
  } else {
    var link = document.createElement('a')
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

const padNumberStartForDate = num => `${num}`.padStart(2, '0')
const JSDateToDateString = date => {
  const year = date.getFullYear()
  const month = padNumberStartForDate(date.getMonth())
  const day = padNumberStartForDate(date.getDate())

  return `${year}-${month}-${day}`
}
const JSDateToExcelDateTime = date => {
  const hours = padNumberStartForDate(date.getHours())
  const minutes = padNumberStartForDate(date.getMinutes())
  const seconds = padNumberStartForDate(date.getSeconds())

  return `${JSDateToDateString(date)} ${hours}:${minutes}:${seconds}`
}

const getScoresColumnsForQuestionnaireType = questionnaireType => {
  const pillars = questionnaireType.pillars

  const columns = []

  pillars.map(pillar => {
    columns.push(
      ...pillar.scores.map(({ key, name }) => ({
        key,
        label: name,
        path: `scores.${pillar.key}.${key}`,
      })),
      { key: pillar.key, label: pillar.name, path: `pillar.${pillar.name}` }
    )
  })

  return columns
}

/* columns prop should be structured as such:
  [
    {
      key: 'String' // this refers to Hasura table column name
      label: 'String' // this will be the label (first row) of the column in the csv
      isDate: Boolean // needed to know if a value needs to be converted to an Excel acceptable date
    },
    {
      ...and so on
    }
  ]
  order of the columns in prop is order they will appear in the CSV file
  */
function DownloadCSVButton({ classes, variables, type, columns, theme }) {
  const pillarColors = [
    theme.palette.primary.light,
    theme.palette.primary.main,
    theme.palette.secondary.dark,
  ]

  const { t, i18n } = useTranslation()

  const [isDownloading, setIsDownloading] = useState(false)
  const [fetchData] = useManualQuery(getNonUserQuestionnairesData)

  const handleOnClick = async () => {
    if (!isDownloading) {
      setIsDownloading(true)

      const { data: result, error } = await fetchData({
        variables: { ...variables, offset: 0, limit: 999999999 },
      })

      if (!error) {
        const data = result.questionnaire_non_users.reverse()

        const scoresColumns = getScoresColumnsForQuestionnaireType(type)

        const csvColumns = [...columns, ...scoresColumns]

        const csvData = [csvColumns.map(({ label }) => label)]

        while (data.length) {
          const dataRow = data.pop()

          const prepData = getContextDataForChartFunc(dataRow.key, dataRow)
          const { assessment } = getAssessmentParts(dataRow.key, i18n)

          const chartData = getQuestionnaireChartData(
            assessment,
            prepData,
            pillarColors
          )

          csvData.push(
            csvColumns.map(({ key, isDate, path }) => {
              const pillarColumn =
                path && path.includes('pillar.') ? path.split('.')[1] : false

              const pillarColumnData = pillarColumn
                ? chartData.find(({ key }) => key === pillarColumn)
                : false

              const pillarColumnScore = pillarColumnData
                ? pillarColumnData.score
                : ''

              return !isDate
                ? path
                  ? pillarColumn
                    ? pillarColumnScore
                    : get(dataRow, path)
                  : dataRow[key] || ''
                : JSDateToExcelDateTime(new Date(dataRow[key]))
            })
          )
        }

        exportToCsv(
          `${JSDateToDateString(new Date())}_free_questionnaires.csv`,
          csvData
        )
      }

      setIsDownloading(false)
    }
  }

  return (
    <Button
      color="secondary"
      variant="contained"
      fullWidth
      onClick={handleOnClick}
      className={classes.root}
    >
      {isDownloading ? (
        <CircularProgress size={14} className={classes.progress} />
      ) : (
        t('Download CSV')
      )}
    </Button>
  )
}

const styles = theme => ({
  root: {
    width: '170px',
  },
  progress: {
    color: theme.palette.background.paper,
  },
})

export default withStyles(styles, { withTheme: true })(DownloadCSVButton)
