import React from 'react'
import T from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import { PaddedContainer } from 'gatsby-components'
import get from 'lodash/get'

const usePageSectionStyles = makeStyles(theme => ({
  wrapper: ({ paletteColor }) => ({
    backgroundColor: paletteColor
      ? get(theme.palette, paletteColor)
      : 'transparent',
    padding: theme.spacing(6, 0),
  }),
}))

/*
  Basic page section expands across screen and restricts content children
  Allows background color to be confiugured
*/
const PageSection = ({ children, className, paletteColor, ...rest }) => {
  const { wrapper } = usePageSectionStyles({ paletteColor })

  return (
    <Box
      className={wrapper}
      component="section"
      data-test-id="page-section"
      {...rest}
    >
      <PaddedContainer className={className}>{children}</PaddedContainer>
    </Box>
  )
}

PageSection.propTypes = {
  className: T.string,
  children: T.any.isRequired,
  paletteColor: T.array, // Array of keys to access a colour from the root of the palette object in theme
}

export default PageSection
