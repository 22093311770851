/* eslint-disable react/prop-types */
import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { useTranslation } from 'react-i18next'
import { Paper, Typography, Grid, withStyles } from '@material-ui/core'
import ReactMarkdown from 'react-markdown'
import Img from 'gatsby-image'

import RichText from '../RichText'

const BoxCarousel = ({
  fixedImageSelector,
  classes,
  namespace,
  boxes,
  bodyIndex,
  dataIndex,
  className,
}) => {
  const { t } = useTranslation()

  function text(key, opts) {
    const txt = t(key, opts)
    if (txt.indexOf('<') === -1) {
      return txt
    }
    return <RichText value={txt} />
  }

  return (
    <Paper className={classes.root}>
      <Carousel
        autoPlay={false}
        timer={500}
        animation="fade"
        indicators
        timeout={500}
        className={classes[className]}
      >
        {boxes.map((box, i) => (
          <Paper
            elevation={0}
            key={`quote_${i}`}
            className={classes[className]}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.quoteGridContainer}
            >
              <Grid item xs={2}>
                <Img
                  className={classes.boxImage}
                  fluid={fixedImageSelector('carousel', box.image)}
                />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h4" className={classes.boxTitle}>
                  {text(
                    box.titleKey ||
                      `findOutMore.body.${bodyIndex}.data.${dataIndex}.boxes.${i}.title`,
                    {
                      defaultValue: box.title,
                      ns: namespace,
                      keySeparator: '.',
                    }
                  )}
                </Typography>
                <ReactMarkdown variant="body1" className={classes.boxText}>
                  {text(
                    box.bodyKey ||
                      `findOutMore.body.${bodyIndex}.data.${dataIndex}.boxes.${i}.body`,
                    {
                      defaultValue: box.body,
                      ns: namespace,
                      keySeparator: '.',
                    }
                  )}
                </ReactMarkdown>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </Carousel>
    </Paper>
  )
}

const styles = theme => ({
  root: {
    // width: '544px',
    // height: '292px',
    borderRadius: '10px',
    '& > *': {
      height: '100%',
    },
  },
  boxTitle: {
    fontSize: 21,
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
  },
  quoteGridContainer: {
    // height: '262px'
  },
  boxText: {
    fontSize: 18,
    lineHeight: 1.2,
    margin: theme.spacing(1, 2, 0, 0),
  },
  boxImage: {
    margin: theme.spacing(2, 2, 0, 2),
  },
  dark: {
    backgroundColor: '#ededed',
  },
})

export default withStyles(styles, { withTheme: true })(BoxCarousel)
