import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Box } from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

let CKEditor
let InlineEditor

if (typeof window !== 'undefined') {
  CKEditor = require('@ckeditor/ckeditor5-react')
  InlineEditor = require('@ckeditor/ckeditor5-build-inline')
}

function countWords(text) {
  const wordDelims = ''
  var normalizedText = text
    .replace(/(\r\n|\n|\r)/gm, ' ')
    .replace(/^\s+|\s+$/g, '')
    .replace(/&nbsp;/g, ' ')
    .replace(/(<([^>]+)>)/gi, ' ')

  var re = wordDelims ? new RegExp('[\\s' + wordDelims + ']+') : /\s+/
  var words = normalizedText.split(re)

  re = wordDelims
    ? new RegExp('^([\\s\\t\\r\\n' + wordDelims + ']*)$')
    : /^([\s\t\r\n]*)$/
  for (var wordIndex = words.length - 1; wordIndex >= 0; wordIndex--) {
    if (!words[wordIndex] || words[wordIndex].match(re)) {
      words.splice(wordIndex, 1)
    }
  }

  return words.length
}

const RichTextEditor = ({
  classes,
  data,
  config = {},
  onChange = () => null,
  lang = 'en',
  isAssessBase,
  disabled,
  maxWordCount,
}) => {
  const { t } = useTranslation()
  const [wordCount, setWordCount] = useState(0)
  const [editor, setEditor] = useState()

  if (lang !== 'en') {
    // languages styles like "cs-CZ" must be styled in two letter format   "cs"
    if (lang.length === 5) lang = lang.substring(0, 2)
    require(`@ckeditor/ckeditor5-build-inline/build/translations/${lang}`)
  }

  const defaultConfig = {
    removePlugins: isAssessBase
      ? [
          'Table',
          'MediaEmbed',
          'Image',
          'ImageCaption',
          'ImageStyle',
          'ImageTextAlternative',
          'ImageToolbar',
          'ImageUpload',
        ]
      : ['Table', 'MediaEmbed'],
    heading: {
      options: [
        { model: 'paragraph', title: 'Body Text' },
        { model: 'heading3', view: 'h3', title: 'Heading 3' },
        { model: 'heading4', view: 'h4', title: 'Heading 4' },
      ],
    },
    language: {
      ui: lang,
      content: lang,
    },
  }

  if (!CKEditor || !InlineEditor) {
    return null
  }

  return (
    <div
      className={`${classes.editor} ${
        isAssessBase
          ? 'richTextEditorAssessBase'
          : 'richTextEditorKnowledgeBase'
      }`}
    >
      <CKEditor
        editor={InlineEditor}
        data={data}
        config={{ ...defaultConfig, ...config }}
        onInit={editor => {
          setWordCount(countWords(editor.getData(true)))
          setEditor(editor)
        }}
        onChange={(event, editor) => {
          setWordCount(countWords(editor.getData(true)))

          const data = editor.getData()
          onChange(data)
        }}
        disabled={disabled}
      />
      {!!maxWordCount && (
        <Box
          className={classnames(
            classes.wordCount,
            wordCount > maxWordCount && classes.wordCountLimit
          )}
          display="flex"
          justifyContent="flex-end"
        >
          {t('word count', { wordCount, maxWordCount })}
        </Box>
      )}
    </div>
  )
}

RichTextEditor.propTypes = {
  data: PropTypes.any,
  config: PropTypes.object,
  classes: PropTypes.object,
  onChange: PropTypes.func,
  lang: PropTypes.string,
  isAssessBase: PropTypes.bool,
  disabled: PropTypes.bool,
  maxWordCount: PropTypes.number,
}

const styles = theme => ({
  '@global': {
    '.ck.ck-content': {
      // border: 0,
      padding: '9px 14px',
      '& > :first-child': {
        marginTop: '2px',
      },
    },
    '.richTextEditorAssessBase': {
      '& .ck.ck-content': {
        backgroundColor: theme.palette.background.light,
        // '& .ck.ck-content': {
        // by default heading 1 maps to h2, heading 2 to h3 etc (but we've customised this to map correctly)
        // see - https://ckeditor.com/docs/ckeditor5/latest/features/headings.html#heading-levels
        // heading 1 and 2 reseved for title and subtitle
        '& p': theme.typography.body,
        '& h3': theme.typography.h3,
        '& h4': theme.typography.h4,
      },
    },
    '.richTextEditorKnowledgeBase': {
      '& .ck.ck-content': {
        backgroundColor: fade(
          theme.articleTypography.input.backgroundColor,
          0.5
        ),
        // by default heading 1 maps to h2, heading 2 to h3 etc (but we've customised this to map correctly)
        // see - https://ckeditor.com/docs/ckeditor5/latest/features/headings.html#heading-levels
        // heading 1 and 2 reseved for title and subtitle
        '& h3': theme.articleTypography.heading3,
        '& h4': theme.articleTypography.heading4,
        '& > p:first-of-type': theme.articleTypography.firstParagraph,
        '& p': theme.articleTypography.paragraph,
        '& ul': theme.articleTypography.bulletedList,
        '& ul li': theme.articleTypography.bulletedListItem,
        '& ol': theme.articleTypography.numberedList,
        '& ol li': theme.articleTypography.numberedListItem,
        '& blockquote': theme.articleTypography.blockquote,
        '& strong': theme.articleTypography.bold,
        '& i': theme.articleTypography.italic,
        '& a': theme.articleTypography.link,
      },
    },
    '.ck.ck-balloon-panel': {
      backgroundColor: theme.articleTypography.input.backgroundColor,
      border: 'none',
      boxShadow: '0 2px 10px 0 rgba(0,0,0,0.1), 0 0 5px 0 rgba(0,0,0,0.1)',
      transform: 'translateY(-4px)',
      position: 'absolute',
      zIndex: 4000,
    },
    '.ck-rounded-corners .ck.ck-balloon-panel, .ck.ck-balloon-panel.ck-rounded-corners': {
      borderRadius: '5px',
    },
    '.ck.ck-toolbar': {
      backgroundColor: theme.articleTypography.input.backgroundColor,
    },
  },
  editor: {
    flex: 1,
  },
  wordCount: {
    marginTop: theme.spacing(1),
  },
  wordCountLimit: {
    color: theme.colorDefinitions.redError,
  },
})

export default withStyles(styles)(RichTextEditor)
