import React, { useState, useContext } from 'react'
import T from 'prop-types'
import {
  withStyles,
  IconButton,
  Button,
  TextField,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import FilterNoneIcon from '@material-ui/icons/FilterNone'
import { useMutation, useManualQuery } from 'graphql-hooks'
import { navigate } from 'gatsby'

import { ConfirmDialog, AuthContext, SelectPicker } from 'gatsby-components'
import {
  getAssessmentDataForContributorOnlyCopy,
  getAssessmentDataForContributorAndAssessorCopy,
  createAssessmentCopyContributorDataOnlyMutation,
  createAssessmentCopyContributorAndAssessorDataMutation,
} from '../queries'

const generateDefaultTitle = title => {
  const titleArr = title.split` `
  let end = titleArr.pop()

  if (end[0] === '(' && end[end.length - 1] === ')') {
    end = end.substring(1, end.length - 1)
    const copyNumber = Number(end)

    if (!isNaN(copyNumber)) return `${titleArr.join` `} (${copyNumber + 1})`
  }

  return `${title} (2)`
}

function AssessmentsTableCopyButton({ assessment, isPlatformAdmin, classes }) {
  const { t } = useTranslation()
  const { id, name: title } = assessment

  const { getUserTokenData } = useContext(AuthContext)
  const { userId, groupId } = getUserTokenData()

  const [newTitle, setNewTitle] = useState(generateDefaultTitle(title))
  const [copyContributorDataOnly, setCopyContributorDataOnly] = useState(true)

  const [createAssessmentCopy] = useMutation(
    copyContributorDataOnly
      ? createAssessmentCopyContributorDataOnlyMutation
      : createAssessmentCopyContributorAndAssessorDataMutation
  )
  const [fetchAssessmentDataForCopy] = useManualQuery(
    copyContributorDataOnly
      ? getAssessmentDataForContributorOnlyCopy
      : getAssessmentDataForContributorAndAssessorCopy
  )

  const copyDataOptions = [
    { name: t('Contributor Data Only'), value: true },
    { name: t('Contributor & Assessor Data'), value: false },
  ]

  const onCopy = id => async () => {
    const fetchData = await fetchAssessmentDataForCopy({
      variables: { id },
    })
    const data = get(fetchData, 'data.assessment_by_pk', {})

    const insertRes = await createAssessmentCopy({
      variables: {
        ...data,
        owner_id: Number(userId),
        group_id: Number(groupId),
        name: newTitle,
      },
    })

    const newAssessmentId = get(
      insertRes,
      'data.insert_assessment.returning[0].id'
    )

    navigate(`/assessment/${data.key}#${newAssessmentId}`)
  }

  return (
    <ConfirmDialog
      onConfirm={onCopy(id)}
      title={t('Copy assessment', {
        assessmentTitle: title,
      })}
      waitForAsync
      inputElement={
        <div className={classes.container}>
          <Typography variant="body2" gutterBottom>
            {t('Name of copied assessment')}
          </Typography>
          <TextField
            value={newTitle}
            onChange={e => setNewTitle(e.target.value)}
            className={classes.input}
          />
          {isPlatformAdmin && (
            <>
              <Typography
                variant="body2"
                gutterBottom
                className={classes.copyDataSelector}
              >
                {t('Which data would you like to be copied?')}
              </Typography>
              <SelectPicker
                data={copyDataOptions}
                selectedValue={copyContributorDataOnly}
                handleChange={val => setCopyContributorDataOnly(val)}
              />
            </>
          )}
        </div>
      }
      okayLabel={t('Copy')}
    >
      <IconButton component={Button}>
        <FilterNoneIcon />
      </IconButton>
    </ConfirmDialog>
  )
}

AssessmentsTableCopyButton.propTypes = {
  assessment: T.obj,
  isPlatformAdmin: T.bool,
}

const styles = theme => ({
  container: {
    margin: theme.spacing(0, 3),
  },
  copyDataSelector: {
    marginTop: theme.spacing(2),
  },
  input: {
    width: '100%',
  },
})

export default withStyles(styles)(AssessmentsTableCopyButton)
