import React, { useRef } from 'react'
import T from 'prop-types'
import RichTextEditor from './RichTextEditor'
import { getCKEUploaderPlugin } from '../../utils/imageUpload'

import debounce from 'lodash/debounce'

const FormikRichTextEditor = ({
  field: { name, value, onChange }, // { name, value, onChange, onBlur }
  articleId,
  config = {},
  noDebounce,
  ...props
}) => {
  const ref = useRef()
  function dispatchChangeEvent(d) {
    if (ref.current) {
      var ev = new Event('input')
      ev.simulated = true
      ref.current.value = d
      ref.current.dispatchEvent(ev)
      onChange({ ...ev, target: ref.current })
    }
  }
  return (
    <>
      <RichTextEditor
        {...props}
        onChange={
          // debounce doesn't work with AutoSaveWatchFormik
          noDebounce ? dispatchChangeEvent : debounce(dispatchChangeEvent, 150)
        }
        data={value}
        config={{
          extraPlugins: [getCKEUploaderPlugin(`uploads/articles/${articleId}`)],
          ...config,
        }}
      />
      <input type="hidden" name={name} ref={ref} />
    </>
  )
}

FormikRichTextEditor.propTypes = {
  field: T.obj,
  articleId: T.string,
  config: T.object,
  noDebounce: T.bool,
}

export default FormikRichTextEditor
