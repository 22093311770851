import React, { useState } from 'react'
import T from 'prop-types'
import {
  withStyles,
  IconButton,
  Button,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import { useMutation, useManualQuery } from 'graphql-hooks'
import { ConfirmDialog } from 'gatsby-components'

import {
  updateAssessmentGroupMutation,
  getAssessmentMoveGroups,
} from '../queries'

function AssessmentsTableMoveButton({ assessment, classes, onSubmit }) {
  const { t } = useTranslation()
  const { id, name: title } = assessment

  const [newGroupId, setNewGroupId] = useState('')
  const [getGroups, { data }] = useManualQuery(getAssessmentMoveGroups, {
    variables: {
      groupId: assessment.group.id,
      organizationId: assessment.group.organization.id,
    },
    useCache: false,
  })

  const [updateAssessmentGroup] = useMutation(updateAssessmentGroupMutation)

  const onConfirm = async () => {
    await updateAssessmentGroup({
      variables: {
        id,
        newGroupId,
      },
    })
    if (onSubmit) {
      onSubmit()
    }
  }

  return (
    <ConfirmDialog
      onOpen={getGroups}
      onConfirm={onConfirm}
      title={t('Move assessment', { title })}
      waitForAsync
      submitDisabled={newGroupId === ''}
      inputElement={
        <div className={classes.container}>
          <Typography variant="body2" gutterBottom>
            {t('Which group would you like this assessment to be moved to?')}
          </Typography>
          {data && (
            <Select
              fullWidth
              onChange={e => setNewGroupId(e.target.value)}
              value={newGroupId}
            >
              {data.group2.map(group => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>
      }
      okayLabel={t('Move')}
    >
      <IconButton component={Button}>
        <DoubleArrowIcon />
      </IconButton>
    </ConfirmDialog>
  )
}

AssessmentsTableMoveButton.propTypes = {
  assessment: T.obj,
  isPlatformAdmin: T.bool,
  onSubmit: T.func,
}

const styles = theme => ({
  container: {
    margin: theme.spacing(0, 3),
  },
})

export default withStyles(styles)(AssessmentsTableMoveButton)
