import React, { useState } from 'react'
import T from 'prop-types'
import ListItem from './ListItem'
import {
  withStyles,
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItem as MuiListItem,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Close from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import TypedChip from '../../TypedChip'

const ENTER_KEY = 13

function AdminSidebar({
  searchedItems,
  setSearchedItems,
  listItems = [],
  classes,
  pageTitle,
}) {
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')

  const handleChange = e => {
    setSearchText(e.target.value)
  }

  const handleSearch = () => {
    setSearchedItems([...searchedItems, searchText])
    setSearchText('')
  }

  const handleKeyDown = e => {
    if (e.keyCode === ENTER_KEY) {
      e.preventDefault()
      handleSearch()
    }
  }

  const handleClearSearch = () => {
    setSearchText('')
  }

  const removeSearchedText = id => () => {
    setSearchedItems([
      ...searchedItems.slice(0, id),
      ...searchedItems.slice(id + 1, searchedItems.length),
    ])
  }

  return (
    <List className={classes.root}>
      <Typography variant="h4">{t(`Find ${pageTitle}`)}</Typography>
      <MuiListItem className={classes.searchListItem}>
        <Input
          autoFocus
          placeholder="Search"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          className={classes.inputClass}
          value={searchText}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                size="small"
                aria-label="Close search"
                onClick={handleClearSearch}
              >
                <Close fontSize="inherit" />
              </IconButton>
            </InputAdornment>
          }
          startAdornment={
            <IconButton
              className={classes.iconButton}
              aria-label="Search"
              onClick={handleSearch}
            >
              <SearchIcon />
            </IconButton>
          }
        />
      </MuiListItem>
      {!!searchedItems.length && (
        <MuiListItem className={classes.searchChipsContainer}>
          {searchedItems.map((item, i) => (
            <TypedChip
              name={item}
              onDelete={removeSearchedText(i)}
              key={`search-item-${i + 1}`}
            />
          ))}
        </MuiListItem>
      )}
      {!searchedItems.length && (
        <Typography className={classes.allText} variant="h4">
          {t('All')}&nbsp;{t(pageTitle)}
        </Typography>
      )}
      {listItems.map(({ key, childLinks = [], to, ...props }) => {
        const childLinksProp = childLinks.map(({ to }) => to)

        return (
          <React.Fragment key={key}>
            <ListItem to={to} childLinks={childLinksProp} {...props} />
            {window?.location.href.includes(to) &&
              childLinks.map(({ key, ...props }) => (
                <ListItem key={key} childLinks={childLinksProp} {...props} />
              ))}
          </React.Fragment>
        )
      })}
    </List>
  )
}

const styles = theme => ({
  root: {},
  searchListItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  inputClass: {
    width: '100%',
    borderRadius: '3px',
    border: `solid 2px ${theme.palette.primary.dark}`,
    '& > .MuiSvgIcon-root': {
      color: theme.palette.primary.dark,
    },
    '&>input': {
      ...theme.typography.h3,
      fontSize: '14px',
      color: theme.palette.primary.dark,
      padding: theme.spacing(0.5, 2, 0.75, 2),
      lineHeight: '17px',
      height: '17px',
      '&::-webkit-input-placeholder': {
        ...theme.typography.h3,
        color: theme.palette.primary.dark,
        lineHeight: '17px',
      },
      '&::-moz-placeholder': {
        ...theme.typography.h3,
        color: theme.palette.primary.dark,
        lineHeight: '17px',
      },
      '&:-ms-input-placeholder': {
        ...theme.typography.h3,
        color: theme.palette.primary.dark,
        lineHeight: '17px',
      },
    },
  },
  iconButton: {
    float: 'right',
    padding: '4px',
  },
  searchChipsContainer: {
    display: 'inline-block',
    paddingLeft: 0,
    paddingBottom: 0,
    paddingRight: 0,
    '& > .MuiChip-root': {
      height: '32px !important',
      margin: theme.spacing(0, 1, 1, 0),
    },
    '& > .MuiChip-deleteIcon': {
      marginBottom: theme.spacing(-0.25),
    },
  },
  allText: {
    margin: theme.spacing(2.25, 0, 2),
  },
})

AdminSidebar.propTypes = {
  listItems: T.array.isRequired,
  searchedItems: T.array.isRequired,
  setSearchedItems: T.func.isRequired,
  classes: T.object,
  pageTitle: T.string,
}

export default withStyles(styles)(AdminSidebar)
