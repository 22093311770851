import React from 'react'
import T from 'prop-types'

import { withStyles, MenuItem, Typography, TextField } from '@material-ui/core'
import { KeyboardArrowUp } from '@material-ui/icons'

function SelectPicker({ classes, selectedValue, handleChange, data }) {
  return (
    <TextField
      fullWidth
      select
      value={selectedValue}
      onChange={e => {
        handleChange(e.target.value)
      }}
      margin="dense"
      SelectProps={{
        classes: {
          root: classes.selectRoot,
          select: classes.selectElem,
          icon: classes.selectIcon,
        },
        IconComponent: KeyboardArrowUp,
      }}
    >
      {data.map(({ name, value }) => (
        <MenuItem key={value} value={value}>
          <Typography component="span">{name}</Typography>
        </MenuItem>
      ))}
    </TextField>
  )
}

const styles = theme => ({
  inputLabel: {
    color: theme.palette.primary.dark,
    fontWeight: 700,
    marginTop: theme.spacing(1),
  },
  selectRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  selectElem: {
    flexGrow: 1,
  },
  selectIcon: {
    color: theme.palette.secondary.main,
  },
})

SelectPicker.propTypes = {
  classes: T.object.isRequired,
  data: T.object,
  handleChange: T.func,
  selectedValue: T.oneOfType([T.string, T.number, T.bool]),
}

export default withStyles(styles)(SelectPicker)
