import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  IconButton,
  Input,
  TableRow,
  TableCell,
  Typography,
  withStyles,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import FilterListIcon from '@material-ui/icons/FilterList'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import { Link } from 'gatsby'
import { useMutation } from 'graphql-hooks'
import get from 'lodash/get'
import debounce from 'lodash/debounce'

import {
  ArticleStatusChip,
  AuthContext,
  PaddedContainer,
  useKnowledgeTypes,
  SEO,
  QueryTable,
  ContentToolbar,
} from 'gatsby-components'

import {
  getArticlesData,
  getUserArticlesData,
  deleteArticleMutation,
} from '../../../../queries'

import { formatDateTime } from 'gatsby-components/src/utils/date'

const ArticleList = ({ classes, path }) => {
  const { getUserTokenData } = useContext(AuthContext)
  const { userId, isPlatformAdmin } = getUserTokenData()
  const [statusFilter, setStatusFilter] = useState()
  const [deleteDialog, setDeleteDialog] = useState(null)
  const [pageTitle, setPageTitle] = useState('All Stories')
  const [deleteArticle] = useMutation(deleteArticleMutation)
  const [filter, setFilter] = useState()

  useEffect(() => {
    const inReview = path === '/needs-review'
    setStatusFilter(inReview ? 'in-review' : undefined)
    setPageTitle(inReview ? 'Needs Review' : 'All Stories')
  }, [path])

  const handleFilterChange = debounce(value => {
    setFilter(value)
  }, 400)

  const knowledgeTypes = useKnowledgeTypes()

  let query
  let variables

  if (isPlatformAdmin) {
    query = getArticlesData
    variables = {
      status: statusFilter,
      excludeKnowledgeType: null,
    }
  } else {
    query = getUserArticlesData
    variables = { createdById: userId }
  }
  if (filter) {
    variables.filterLike = `%${filter}%`
  }

  const headers = [
    { id: 'title', label: 'Title', sortable: true },
    { id: 'createdBy', label: 'Created By' },
    { id: 'authros', label: 'Authors' },
    { id: 'updated_at', label: 'Last Updated', sortable: true },
    { id: 'knowledge_type', label: 'Type', sortable: true },
    { id: 'status', label: 'Status', sortable: true },
    { id: 'edit', label: 'Edit', cellProps: { align: 'center' } },
    { id: 'delete', label: 'Delete', cellProps: { align: 'center' } },
    { id: 'view', label: 'View', cellProps: { align: 'center' } },
  ]

  return (
    <PaddedContainer>
      <SEO title={pageTitle} />
      <ContentToolbar pageTitle="Content" />
      <div className={classes.filter}>
        <Typography color="secondary" variant="h4">
          Filter (title & authors)
        </Typography>
        <Input
          endAdornment={<FilterListIcon color="secondary" />}
          onChange={e => handleFilterChange(e.target.value)}
        />
      </div>
      <QueryTable
        headers={headers}
        query={query}
        variables={variables}
        orderBy={{ updated_at: 'desc' }}
        renderTableBody={({ data, refetch }) => (
          <>
            {data &&
              data.article.map(article => (
                <TableRow
                  className={classes.row}
                  hover
                  key={article.id}
                  size="small"
                >
                  <TableCell>{article.title}</TableCell>
                  <TableCell>
                    {get(article, 'createdBy.first_name')}{' '}
                    {get(article, 'createdBy.last_name')}
                  </TableCell>
                  <TableCell>
                    {get(article, 'authors').map(({ author }, idx) => (
                      <div key={`author-${idx}`}>
                        {author.first_name}&nbsp;{author.last_name}
                        <br />
                      </div>
                    ))}
                  </TableCell>
                  <TableCell>{formatDateTime(article.updated_at)}</TableCell>
                  <TableCell>
                    {knowledgeTypes[article.knowledge_type]}
                  </TableCell>
                  <TableCell>
                    <ArticleStatusChip status={article.status} />
                  </TableCell>
                  <TableCell align="center" padding="none">
                    <IconButton
                      className={classes.icon}
                      component={Link}
                      disabled={
                        article.status !== 'in-progress' && !isPlatformAdmin
                      }
                      to={`/my-content/edit/${article.id}`}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center" padding="none">
                    <IconButton
                      className={classes.icon}
                      onClick={() => setDeleteDialog(article)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center" padding="none">
                    <IconButton
                      className={classes.icon}
                      component={Link}
                      to={`/my-content/preview/${article.id}`}
                    >
                      <InsertDriveFileIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            <Dialog
              open={deleteDialog !== null}
              onClose={() => setDeleteDialog(null)}
            >
              <DialogTitle id="alert-dialog-title">
                {'Delete Article?'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete this article?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDeleteDialog(null)} color="secondary">
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onClick={async () => {
                    if (deleteDialog !== null) {
                      await deleteArticle({
                        variables: { articleId: deleteDialog.id },
                      })
                      setDeleteDialog(null)
                      refetch()
                    }
                  }}
                >
                  Delete Article
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      />
    </PaddedContainer>
  )
}

ArticleList.propTypes = {
  classes: PropTypes.object,
  path: PropTypes.string,
}

const styles = theme => ({
  filter: {
    marginBottom: theme.spacing(1),
    '&>h4': {
      marginBottom: theme.spacing(0.5),
    },
    '& input': {
      minWidth: '250px',
    },
    '&>div': {
      paddingRight: theme.spacing(0.5),
    },
  },
  icon: {
    ...theme.iconLight,
  },
  row: {
    '& > td': {
      verticalAlign: 'top',
    },
  },
})

export default withStyles(styles)(ArticleList)
