import React from 'react'
import T from 'prop-types'
import { withStyles } from '@material-ui/core'

const RichText = ({ classes, value }) => (
  <div
    className={classes.richContent}
    dangerouslySetInnerHTML={{ __html: value }}
    data-testid="rich-text"
  />
)

RichText.propTypes = {
  value: T.string,
  classes: T.object.isRequired,
}

const styles = () => ({
  richContent: {
    '@media print': {
      display: 'block',

      '& p': {
        display: 'block',
        width: '100%',
      },

      '& h3,h4': {
        padding: '10px 0',
        breakInside: 'avoid-page',
        fontSize: 12,
        display: 'block',
        width: '100%',
      },
    },
  },
})

export default withStyles(styles)(RichText)
