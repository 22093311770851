import React, { useContext, useState } from 'react'
import T from 'prop-types'
import { Router, Redirect } from '@reach/router'
import { useQuery } from 'graphql-hooks'
import { Grid, Typography, withStyles } from '@material-ui/core'
import {
  Traffic as TrafficIcon,
  People as PeopleIcon,
  Assignment as AssignmentIcon,
  Face as FaceIcon,
  Forum as ForumIcon,
  BusinessCenter as BusinessCenterIcon,
} from '@material-ui/icons'

import { AuthContext } from '../authorization/AuthWrapper'
import PaddedContainer from '../PaddedContainer'
import ActionsTable from './ActionsTable'
import PendingUsers from './PendingUsers'
import AllUsers from './AllUsers'
import AdminSidebar from './AdminSidebar'
import UserGroups from './UserGroups'
import UserOrganization from './UserOrganization'
import GroupUsers from './GroupUsers'
import OrganizationUsers from './OrganizationUsers'
import AssessmentsTable from '../../../../assess-base/src/components/AssessmentsTable'
import FreeQuestionnaireTable from '../../../../assess-base/src/components/FreeQuestionnaireTable'

import { useTranslation } from 'react-i18next'

import { getCountForAdminSidebar } from '../../../queries'

function AdminRoute({ SEO, additionalSections, classes }) {
  const { t } = useTranslation()
  const { getUserTokenData, getUserAuth } = useContext(AuthContext)
  const { isPlatformAdmin, isAdmin, orgId } = getUserTokenData()

  const [pageTitle, setPageTitle] = useState('')
  const [searchedItems, setSearchedItems] = useState([])

  const userCanAccess = {
    actions: isPlatformAdmin,
    pendingUsers: getUserAuth('group-admin'),
    allUsers: getUserAuth('group-admin'),
    groups: getUserAuth('group-admin'),
    organizations: getUserAuth('org-admin'),
    assessments: true,
    freeQuestionnaires: isPlatformAdmin,
  }
  const defaultSection = 'assessments'

  const { data } = useQuery(getCountForAdminSidebar, {
    variables: {
      internalActionItems: !isPlatformAdmin,
    },
  })

  const sidebarListItems = [
    {
      name: t('Actions'),
      key: 'actions',
      Icon: TrafficIcon,
      to: '/admin/actions',
      chipValue: data?.actions_aggregate.aggregate.count || 0,
      hide: !userCanAccess.actions,
    },
    {
      name: t('Organisations'),
      key: 'organisations',
      Icon: BusinessCenterIcon,
      chipValue: data?.organization_aggregate.aggregate.count || 0,
      to: '/admin/organisations',
      hide: !userCanAccess.organizations,
    },
    {
      name: t('Groups'),
      key: 'groups',
      Icon: PeopleIcon,
      chipValue: data?.group2_aggregate.aggregate.count || 0,
      to: '/admin/groups',
    },
    {
      name: t('Assessments'),
      key: 'assessments',
      Icon: AssignmentIcon,
      chipValue: data?.assessment_aggregate.aggregate.count || 0,
      to: '/admin/assessments',
      childLinks: [
        {
          name: t('In progress'),
          key: 'in-progress',
          to: '/admin/assessments/in-progress',
        },
        {
          name: t('Submitted'),
          key: 'submitted',
          to: '/admin/assessments/submitted',
        },
        {
          name: t('Closed'),
          key: 'closed',
          to: '/admin/assessments/closed',
        },
      ],
    },
    {
      name: t('Users'),
      key: 'users',
      Icon: FaceIcon,
      chipValue: data?.user_aggregate.aggregate.count || 0,
      to: '/admin/users',
      childLinks: [
        {
          name: t('Pending'),
          key: 'pending',
          to: '/admin/users/pending',
        },
      ],
    },
    {
      name: t('Questionnaires'),
      key: 'questionnaires',
      Icon: ForumIcon,
      chipValue: data?.questionnaire_non_users_aggregate.aggregate.count || 0,
      to: '/admin/free-questionnaires',
      hide: !userCanAccess.freeQuestionnaires,
    },
  ]

  const assessmentWhereClause = searchedItems.reduce(
    (a, item) => ({
      _or: [
        ...(a._or || []),
        { name: { _ilike: `%${item}%` } },
        {
          group: {
            _or: [
              { name: { _ilike: `%${item}%` } },
              { organization: { name: { _ilike: `%${item}%` } } },
            ],
          },
        },
      ],
    }),
    {}
  )

  return (
    <PaddedContainer>
      {SEO && <SEO title={`${pageTitle} | Admin`} />}
      <Grid container spacing={4}>
        <Grid item className={classes.sidebarGrid}>
          <AdminSidebar
            pageTitle={pageTitle}
            userCanAccess={userCanAccess}
            additionalSections={additionalSections}
            listItems={sidebarListItems.filter(({ hide }) => !hide)}
            searchedItems={searchedItems}
            setSearchedItems={setSearchedItems}
          />
        </Grid>
        <Grid item className={classes.tableGrid}>
          <Router>
            {additionalSections.map(
              ({ path, Component, pageTitle, tableTitle, tableSubtitle }) => (
                <AdminSection
                  key={path}
                  path={path}
                  tableTitle={tableTitle}
                  tableSubtitle={tableSubtitle}
                  component={Component || PendingUsers}
                  applyPageTitle={() => setPageTitle(t(pageTitle))}
                />
              )
            )}
            {userCanAccess.actions && (
              <AdminSection
                path="actions"
                component={ActionsTable}
                tableTitle={t('Actions')}
                tableSubtitle={t('Most recent')}
                applyPageTitle={() => setPageTitle(t('Actions'))}
              />
            )}
            {userCanAccess.freeQuestionnaires && (
              <AdminSection
                path="free-questionnaires"
                component={FreeQuestionnaireTable}
                tableTitle={t('Free questionnaires')}
                tableSubtitle={t('All free questionnaires')}
                applyPageTitle={() => setPageTitle(t('Free questionnaires'))}
              />
            )}
            {userCanAccess.pendingUsers && (
              <AdminSection
                path="users/pending"
                component={PendingUsers}
                tableTitle={t('Pending Users')}
                tableSubtitle={t('All pending users')}
                applyPageTitle={() => setPageTitle(t('Pending Users'))}
                whereClause={
                  isPlatformAdmin
                    ? searchedItems.reduce(
                        (a, item) => ({
                          _and: [
                            {
                              _or: [
                                ...(a._and?.[0]._or || []),
                                { email: { _ilike: `%${item}%` } },
                                { first_name: { _ilike: `%${item}%` } },
                                { last_name: { _ilike: `%${item}%` } },
                                {
                                  organization: {
                                    name: { _ilike: `%${item}%` },
                                  },
                                },
                                {
                                  user_group2s: {
                                    group: { name: { _ilike: `%${item}%` } },
                                  },
                                },
                              ],
                            },
                            { pending: { _eq: true } },
                          ],
                        }),
                        {
                          _and: [{ pending: { _eq: true } }],
                        }
                      )
                    : searchedItems.reduce(
                        (a, item) => ({
                          _and: [
                            {
                              _or: [
                                ...(a._and?.[0]._or || []),
                                { email: { _ilike: `%${item}%` } },
                                { first_name: { _ilike: `%${item}%` } },
                                { last_name: { _ilike: `%${item}%` } },
                                {
                                  organization: {
                                    name: { _ilike: `%${item}%` },
                                  },
                                },
                                {
                                  user_group2s: {
                                    group: { name: { _ilike: `%${item}%` } },
                                  },
                                },
                              ],
                            },
                            {
                              _and: [
                                { pending: { _eq: true } },
                                {
                                  organization: {
                                    _or: [
                                      { id: { _eq: orgId } },
                                      {
                                        parent_organization: {
                                          id: { _eq: orgId },
                                        },
                                      },
                                      {
                                        parent_organization: {
                                          parent_organization: {
                                            id: { _eq: orgId },
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          ],
                        }),
                        {
                          _and: [
                            { pending: { _eq: true } },
                            {
                              organization: {
                                _or: [
                                  { id: { _eq: orgId } },
                                  {
                                    parent_organization: {
                                      id: { _eq: orgId },
                                    },
                                  },
                                  {
                                    parent_organization: {
                                      parent_organization: {
                                        id: { _eq: orgId },
                                      },
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        }
                      )
                }
              />
            )}
            {userCanAccess.allUsers && (
              <AdminSection
                path="users"
                component={AllUsers}
                tableTitle={t('Users')}
                tableSubtitle={t('All Users')}
                applyPageTitle={() => setPageTitle(t('Users'))}
                whereClause={searchedItems.reduce(
                  (a, item) => ({
                    _and: [
                      {
                        _or: [
                          ...(a._and?.[0]._or || []),
                          { email: { _ilike: `%${item}%` } },
                          { first_name: { _ilike: `%${item}%` } },
                          { last_name: { _ilike: `%${item}%` } },
                          { organization: { name: { _ilike: `%${item}%` } } },
                          {
                            user_group2s: {
                              group: { name: { _ilike: `%${item}%` } },
                            },
                          },
                        ],
                      },
                      { active: { _eq: true } },
                      { pending: { _eq: false } },
                    ],
                  }),
                  {
                    _and: [
                      { active: { _eq: true } },
                      { pending: { _eq: false } },
                    ],
                  }
                )}
              />
            )}
            {userCanAccess.groups && (
              <AdminSection
                path="groups"
                component={UserGroups}
                tableTitle={t('Groups')}
                tableSubtitle={t('All groups')}
                applyPageTitle={() => setPageTitle(t('Groups'))}
                whereClause={searchedItems.reduce(
                  (a, item) => ({
                    _or: [
                      ...(a._or || []),
                      { name: { _ilike: `%${item}%` } },
                      { organization: { name: { _ilike: `%${item}%` } } },
                    ],
                  }),
                  {}
                )}
              />
            )}
            {userCanAccess.organizations && (
              <AdminSection
                path="organisations"
                component={UserOrganization}
                tableTitle={t('Organisations')}
                tableSubtitle={t('All organisations')}
                applyPageTitle={() => setPageTitle(t('Organisations'))}
                whereClause={searchedItems.reduce(
                  (a, item) => ({
                    _or: [...(a._or || []), { name: { _ilike: `%${item}%` } }],
                  }),
                  {}
                )}
              />
            )}
            {userCanAccess.groups && (
              <AdminSection
                path="groups/:groupIdString/:groupName"
                component={GroupUsers}
                tableTitle={t('Actions')}
                tableSubtitle={t('Most recent')}
                applyPageTitle={({ groupName }) =>
                  setPageTitle(t('UsersInGroup', { groupName }))
                }
              />
            )}
            {userCanAccess.organizations && (
              <AdminSection
                path="organisations/:orgIdString/:orgName"
                component={OrganizationUsers}
                tableTitle={t('Actions')}
                tableSubtitle={t('Most recent')}
                applyPageTitle={({ orgName }) =>
                  setPageTitle(t('UsersInOrg', { orgName }))
                }
              />
            )}
            {userCanAccess.assessments && (
              <AdminSection
                path="assessments"
                component={props => (
                  <Grid item style={{ maxWidth: '887px' }}>
                    <AssessmentsTable
                      isAdmin={isAdmin}
                      isPlatformAdmin={isPlatformAdmin}
                      isInAdminArea
                      {...props}
                    />
                  </Grid>
                )}
                tableTitle={t('Assessments')}
                tableSubtitle={t('All assessments')}
                applyPageTitle={() => setPageTitle(t('Assessments'))}
                whereClause={assessmentWhereClause}
              />
            )}
            {sidebarListItems
              .find(({ key }) => key === 'assessments')
              ?.childLinks.map(({ key, name, to }) => (
                <AdminSection
                  key={key}
                  path={
                    to.includes('/admin/') ? to.substring('/admin/'.length) : to
                  }
                  component={props => (
                    <Grid item style={{ maxWidth: '887px' }}>
                      <AssessmentsTable
                        isAdmin={isAdmin}
                        isPlatformAdmin={isPlatformAdmin}
                        isInAdminArea
                        {...props}
                      />
                    </Grid>
                  )}
                  tableTitle={t('Assessments')}
                  tableSubtitle={t(`${name} assessments`)}
                  applyPageTitle={() => setPageTitle(t('Assessments'))}
                  whereClause={
                    !searchedItems.length
                      ? { status: { _eq: key } }
                      : {
                          _and: [
                            { status: { _eq: key } },
                            assessmentWhereClause,
                          ],
                        }
                  }
                />
              ))}
            <Redirect
              default
              noThrow
              from="/admin"
              to={`/admin/${defaultSection}`}
            />
          </Router>
        </Grid>
      </Grid>
    </PaddedContainer>
  )
}

const styles = theme => ({
  sidebarGrid: {
    borderRight: 'solid 1px rgba(151,151,151, 0.19)',
    width: '243px',
  },
  tableGrid: {
    width: '1069px',
  },
  tableSubtitle: { color: '#59607d', margin: theme.spacing(0.5, 0, 3.5) },
})

AdminRoute.defaultProps = {
  additionalSections: [],
}

AdminRoute.propTypes = {
  SEO: T.elementType,
  additionalSections: T.array.isRequired,
  classes: T.object,
}

const AdminSection = withStyles(styles)(
  ({
    component: AdminComponent,
    applyPageTitle,
    tableTitle,
    tableSubtitle,
    classes,
    ...props
  }) => {
    applyPageTitle(props)
    return (
      <>
        <Typography variant="h1">{tableTitle}</Typography>
        <Typography variant="h3" className={classes.tableSubtitle}>
          {tableSubtitle}
        </Typography>
        <AdminComponent {...props} />
      </>
    )
  }
)

AdminSection.propTypes = {
  component: T.elementType.isRequired,
  applyPageTitle: T.func.isRequired,
  tableTitle: T.string,
  tableSubtitle: T.string,
}

export default withStyles(styles)(AdminRoute)
