import React, { createContext, useState } from 'react'
import T from 'prop-types'
import { Auth } from 'aws-amplify'
import { find, get } from 'lodash'

const isBrowser = typeof window !== 'undefined'
const HASURA_CLAIMS_NAMESPACE = 'https://hasura.io/jwt/claims'
const CUSTOM_CLAIMS_NAMESPACE = 'x-raw-salmon-claims'
const CUSTOM_CLAIMS_CONTRIBUTOR_KEY = 'x-assess-base-contributor'
const CUSTOM_CLAIMS_CONTRIBUTORS_KEY = 'x-assess-base-contributors'
const CUSTOM_CLAIMS_ASSESSOR_KEY = 'x-assess-base-assessor'
const CUSTOM_CLAIMS_ASSESSORS_KEY = 'x-assess-base-assessors'
const CUSTOM_CLAIMS_EFQM_MEMBER = 'x-efqm-member'
const HASURA_DEFAULT_ROLE_KEY = 'x-hasura-default-role'
const HASURA_USER_ID = 'x-hasura-user-id'
const HASURA_GROUP_ID = 'x-hasura-group-id'
const HASURA_ORG_ID = 'x-hasura-org-id'
const HASURA_CERT_ID = 'x-hasura-certificate-id'
const ROLES_PERMISSIONS = {
  public: 0,
  user: 1,
  'group-admin': 3,
  'org-admin': 7,
  admin: 15,
}
const ORG_PERMISSIONS = {
  public: 0,
  company: 1,
  partner: 3,
  platform: 7,
}

export const AuthContext = createContext({ isAuthInitialized: false })

const isAuthenticatedSync = () => isBrowser && !!Auth.user

const extractTokenPayload = dataKey => {
  if (!Auth.user) {
    return null
  }
  const tokenPayload = get(Auth, 'user.signInUserSession.idToken.payload')
  if (!tokenPayload) {
    return null
  }
  const claims =
    CUSTOM_CLAIMS_NAMESPACE in tokenPayload
      ? {
          ...JSON.parse(tokenPayload[HASURA_CLAIMS_NAMESPACE]),
          ...JSON.parse(tokenPayload[CUSTOM_CLAIMS_NAMESPACE]),
        }
      : {
          ...JSON.parse(tokenPayload[HASURA_CLAIMS_NAMESPACE]),
        }
  return claims[dataKey] || null
}

export function AuthWrapper({ initIsAuthInitializedForTesting, children }) {
  const [userOrgs, setUserOrgs] = useState([])
  const [userCertificates, setUserCertificates] = useState([])
  const [isAuthInitialized, setIsAuthInitialized] = useState(
    initIsAuthInitializedForTesting || false
  )

  /**
   * Returns an object detailing a user's permissions
   *
   * @return {object} The current user's permissions
   */
  const getUserTokenData = assessmentId => {
    const contributorsPayload = extractTokenPayload(
      CUSTOM_CLAIMS_CONTRIBUTORS_KEY
    )
    const assessorsPayload = extractTokenPayload(CUSTOM_CLAIMS_ASSESSORS_KEY)

    const data = {
      isAuthenticated: isAuthInitialized && isAuthenticatedSync(),
      isEfqmMember: extractTokenPayload(CUSTOM_CLAIMS_EFQM_MEMBER) || false,
      isAdmin: hasPermissions('group-admin'),
      isOrgAdmin: hasPermissions('org-admin'),
      isPartner: hasOrgPermissions('partner'),
      isPlatform: hasOrgPermissions('platform'),
      isPlatformAdmin:
        hasPermissions('org-admin') && hasOrgPermissions('platform'),
      isContributor:
        assessmentId && contributorsPayload
          ? !!contributorsPayload.find(id => id === assessmentId)
          : extractTokenPayload(CUSTOM_CLAIMS_CONTRIBUTOR_KEY) || false,
      isAssessor:
        assessmentId && assessorsPayload
          ? !!assessorsPayload.find(id => id === assessmentId)
          : extractTokenPayload(CUSTOM_CLAIMS_ASSESSOR_KEY) || false,
      userId: extractTokenPayload(HASURA_USER_ID),
      groupId: getOneGroup(),
      orgId: extractTokenPayload(HASURA_ORG_ID),
      role: getUserRole(),
    }

    data.isEfqmAdmin = data.isEfqmMember && data.isOrgAdmin
    data.isPartnerAdmin = data.isPartner && data.isOrgAdmin

    return data
  }

  /**
   * Determines if the given user qualifies based on the qualification required. Checks against role, org-type, and licenses
   *
   * @param {string} reqRole The minimum role, org-type, or License at which the permission check is allowed to return true
   * @return {boolean} Whether or not the current user qualifies for the permission checked
   */
  const getUserAuth = reqAuth => {
    if (!isAuthenticatedSync()) return false
    if (!reqAuth || reqAuth === undefined) return true

    const rolePerms = Object.keys(ROLES_PERMISSIONS)
    if (rolePerms.includes(reqAuth.toLowerCase()))
      return hasPermissions(reqAuth.toLowerCase())
    const orgPerms = Object.keys(ORG_PERMISSIONS)
    if (orgPerms.includes(reqAuth.toLowerCase()))
      return hasOrgPermissions(reqAuth.toLowerCase())

    switch (reqAuth.toLowerCase()) {
      case 'contributor':
        return extractTokenPayload(CUSTOM_CLAIMS_CONTRIBUTOR_KEY)
      case 'assessor':
        return extractTokenPayload(CUSTOM_CLAIMS_ASSESSOR_KEY)
      default:
        return hasCertificatePermissions(reqAuth.toLowerCase())
    }
  }

  /**
   * Get the user role from the Hasura JWT Token
   *
   * @return {string} The user's role, with appended group if necessary
   */
  const getUserRole = () => {
    if (!isAuthenticatedSync()) return 'public'
    try {
      return extractTokenPayload(HASURA_DEFAULT_ROLE_KEY)
    } catch (err) {
      return 'public'
    }
  }

  /**
   * Get the user's organization
   *
   * @return {object} The user's organization: {id, type, name}
   */
  const getUserOrg = () => {
    try {
      const orgsTaxonomyQueryResult = userOrgs
      const orgId = extractTokenPayload(HASURA_ORG_ID)
      return find(orgsTaxonomyQueryResult.raw_salmon.organization, {
        id: Number(orgId),
      })
    } catch (err) {
      return undefined
    }
  }

  /**
   * Determine if user has group
   *
   * @param {integer} groupId The id of the group being checked against
   * @return {boolean} Whether or not the user belongs to this group
   */
  const userHasGroup = groupId => {
    const groups = extractTokenPayload(HASURA_GROUP_ID).slice(1, -1)
    const groupArray = groups.split(',')
    return groupArray.map(g => Number(g)).includes(groupId)
  }

  /**
   * Get an array of user groupIds
   *
   * @return {array} The user's group ids
   */
  const getUserGroups = () => {
    try {
      const groups = extractTokenPayload(HASURA_GROUP_ID).slice(1, -1)
      return groups.trim().split(',')
    } catch (err) {
      return undefined
    }
  }

  const getOneGroup = () => {
    const groups = getUserGroups()
    return groups !== undefined ? groups[0] : undefined
  }

  /**
   * Checks permission level of user's organization against required permission level
   *
   * @param {string} reqRole The permission level required
   * @return {boolean} Whether or not the user's organization has the required permission level
   */
  const hasOrgPermissions = reqType => {
    const orgData = getUserOrg()
    const type = orgData?.type || 'public'

    return (
      (ORG_PERMISSIONS[type] & ORG_PERMISSIONS[reqType]) ===
      ORG_PERMISSIONS[reqType]
    )
  }

  /**
   * Checks permission level of user against required license
   *
   * @param {string} reqLicense The license required
   * @return {boolean} Whether or not the user has the required license
   */
  const hasCertificatePermissions = reqCertKey => {
    const allCerts = userCertificates
    const reqCert = find(allCerts.raw_salmon.organization, {
      key: String(reqCertKey),
    })
    const userCerts = extractTokenPayload(HASURA_CERT_ID).slice(1, -1)
    const certArray = userCerts.split(',')
    return certArray.includes(reqCert.id)
  }

  /**
   * Checks permission level of user against required permission level
   *
   * @param {string} reqRole The permission level required
   * @return {boolean} Whether or not the user has the required permission level
   */
  const hasPermissions = reqRole => {
    const role = getUserRole()
    return (
      (ROLES_PERMISSIONS[role] & ROLES_PERMISSIONS[reqRole]) ===
      ROLES_PERMISSIONS[reqRole]
    )
  }

  /**
   * Checks is user has permission to save a questionnaire to the DB
   *
   * @param {number} reqRole The permission level required
   * @return {boolean} Whether or not the user has the required permission level
   */
  const saveQuestionnaireToDB = assessmentId => {
    const { isAuthenticated, isAdmin, isContributor } = getUserTokenData()
    return assessmentId !== null
      ? isAuthenticated && (isAdmin || isContributor)
      : isAuthenticated && isAdmin
  }

  const auth = {
    isAuthInitialized,
    getUserTokenData,
    getUserAuth,
    getUserRole,
    getUserOrg,
    hasPermissions,
    hasOrgPermissions,
    userHasGroup,
    getUserGroups,
    isAuthenticatedSync,
    setUserOrgs,
    setUserCertificates,
    saveQuestionnaireToDB,
    setIsAuthInitialized,
  }

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}
AuthWrapper.propTypes = {
  isAuthInitialized: T.bool,
  initIsAuthInitializedForTesting: T.bool,
  children: T.node,
}
